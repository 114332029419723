import { Container, Heading, Grid, Box, WithIcon, Icon } from '@djrhails/watcher-ui'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useMorphContract } from '../../hooks/useContract'
import { useActiveEthereum } from '../../hooks/useActiveEthereum'
import { useHistory } from 'react-router-dom'
import SuperFormula, { SuperFormulaParameters } from '../../components/SuperFormula'

function Morph({ id }: {id: number }): React.ReactElement {
  const { account } = useActiveEthereum()
  const history = useHistory()
  const contract = useMorphContract()
  const [claimed, setClaimed] = useState(true)
  const [hovered, setHovered] = useState(false)
  const [params, setParams] = useState<SuperFormulaParameters>()

  const claim = () => {
    console.log(contract)
    contract?.mintTo(account)
      .then(() => {
        history.push('/tokens')
      })
  }

  useEffect(() => {
    contract?.ownerOf(id)
      .then(() => setClaimed(true))
      .catch(() => setClaimed(false))
  }, [contract])

  useEffect(() => {
    fetch(`https://api.isomorph.network/1/morph/${id}/params`)
      .then((p) => p.json())
      .then((p) => setParams(p))
  }, [id])

  return <Box
    onClick={claim}
    onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}
  >
    <WithIcon icon={<Icon
      name={claimed ? 'Cancel' : 'CheckCircle'}
      color={claimed ? 'danger' : 'success'}
      size="2rem"
    />}>
      <SuperFormula parameters={params} animated={hovered || undefined}/>
    </WithIcon>
  </Box>
}

export const Adopt: React.FC = () => {
  return (
    <Container as="section">
      <Heading.h2 mt={8}>Claim your favourite.</Heading.h2>
      <Heading.h4 my={8}>Your chosen MORPH will be uniquely owned by you.</Heading.h4>
      <Grid width="full" spacing={8} columns={[1, 1, 2, 3, 3]}>
        {_.range(2, 20).map((i) => 
          <Morph key={i} id={i}/>)}
      </Grid>
    </Container>
  )
}

export default Adopt