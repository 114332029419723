/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AbstractConnector } from '@web3-react/abstract-connector'
import { ConnectorUpdate } from '@web3-react/types'
import sdk, { Account, Algodv2, Indexer } from 'algosdk'
import { ChainDict, ChainId } from '../constants/ChainId'
import invariant from 'tiny-invariant'

export interface NetworkV2ConnectorArguments {
  urls: Partial<ChainDict<string>>;
  indexers: Partial<ChainDict<string>>;
  defaultChainId: ChainId;
  apiKey?: string;
}

interface AlgorandProvider {
  algod: Algodv2;
  indexer: Indexer;
}

export class NetworkV2Connector extends AbstractConnector {
  private readonly providers: Partial<ChainDict<AlgorandProvider>>
  public currentChainId: ChainId
  public sk?: Uint8Array;
  private account?: string

  constructor({ urls, indexers, defaultChainId, apiKey }: NetworkV2ConnectorArguments) {
    const supportedChainIds = Object.keys(urls).map((k): ChainId => Number(k) as ChainId)

    invariant(defaultChainId || supportedChainIds.length === 1, 'defaultChainId is a required argument with >1 url')
    super({ supportedChainIds: supportedChainIds })

    this.currentChainId = defaultChainId || supportedChainIds[0] as ChainId
    let headers = {}

    if (apiKey) {
      headers = {
        'x-api-key': apiKey,
      }
    }

    this.providers = supportedChainIds.reduce<Partial<ChainDict<AlgorandProvider>>>(
      (accumulator, chainId) => {
        invariant(urls[chainId] !== undefined, `No url found for chainId ${chainId}`)
        invariant(indexers[chainId] !== undefined, `No indexer found for chainId ${chainId}`)

        accumulator[chainId] = {
          algod: new sdk.Algodv2(headers, urls[chainId] as string, ''),
          indexer: new sdk.Indexer(headers, indexers[chainId] as string, ''),
        }
        return accumulator
      }, {})
  }

  public async activate(): Promise<ConnectorUpdate> {
    return { provider: this.providers[this.currentChainId], chainId: this.currentChainId, account: null }
  }

  public async getProvider(): Promise<AlgorandProvider> {
    invariant(this.providers[this.currentChainId] !== undefined, `No provider found for currentChainId ${this.currentChainId}`)
    return this.providers[this.currentChainId]!
  }

  public async getChainId(): Promise<number> {
    return this.currentChainId
  }

  public async getAccount(): Promise<string | null> {
    return this.account ?? null
  }

  public deactivate(): void {
    return
  }

  public changeKeys(keys?: Account): void {
    invariant(keys && keys.addr && sdk.isValidAddress(keys.addr), `Not a valid address for ${keys?.addr}`)
    this.account = keys.addr
    this.sk = keys.sk
    this.emitUpdate({})
  }

  public changeChainId(chainId: ChainId): void {
    invariant(Object.keys(this.providers).includes(chainId.toString()), `No url found for chainId ${chainId}`)
    this.currentChainId = chainId
    this.emitUpdate({ provider: this.providers[this.currentChainId], chainId })
  }
}