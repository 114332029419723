import { getNetwork } from '@ethersproject/networks'
import { getDefaultProvider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import invariant from 'tiny-invariant'
import warning from 'tiny-warning'

import { ChainId } from '../index'
import EIP20_INTERFACE from '../abis/eip20'
import { Tracker } from './Tracker'

export class EIP20 extends Tracker {
  static async fetchData(
    chainId: ChainId,
    address: string,
    provider = getDefaultProvider(getNetwork(chainId)),
    symbol?: string,
    name?: string
  ): Promise<EIP20> {

    const parsedDecimals = await new Contract(address, EIP20_INTERFACE, provider).decimals()

    return new EIP20(chainId, address, parsedDecimals, symbol, name)
  }

  constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    // TODO(DJRHails): Validate decimals / address
    const checksummedAddress = address
    try {
      const checksummedAddress = getAddress(address)
      warning(address === checksummedAddress, `${address} is not checksummed.`)
    } catch (error) {
      invariant(false, `${address} is not a valid address.`)
    }

    super(chainId, checksummedAddress, decimals, symbol, name)
  }
}