import React from 'react'
import {
  Text,
  Grid,
  Container
} from '@djrhails/watcher-ui'
import { GradientChainSplit } from './GradientChainSplit'
import { useSelector } from 'react-redux'
import { AppState } from '../../state'
import { Block } from '../../components/Block/Block'
import { useWorth } from '../../state/assets/hooks'
import { Network } from '../../constants'

export const BlocksSection: React.FC = () => {
  const totals = useSelector((state: AppState) => state.assets.totals)
  const ethWorth = useWorth(Network.Ethereum)
  const algoWorth = useWorth(Network.Algorand)

  return <Container as="section">
    <Grid columns={[1, 1, 3]} width="full" spacing={4}>
      <Block title="Total Ethereum Assets">
        <Text fontSize={3} fontWeight="bold" color="brand">
          £{ethWorth.toFixed(2)}
        </Text>
      </Block>

      <Block title="Chain Split">
        <GradientChainSplit split={4 / (4 + totals.algo) } />
      </Block>

      <Block title="Total Algorand Assets">
        <Text fontSize={3} fontWeight="bold" color="accent">
            £{algoWorth.toFixed(2)}
        </Text>
      </Block>
    </Grid>
  </Container>
}