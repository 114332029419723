import React, { Suspense, PropsWithChildren } from 'react'
import { WatcherProvider, StickyFooter, Box } from '@djrhails/watcher-ui'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import Dashboard from './Dashboard'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Web3ReactManager } from '../components/Web3ReactManager'
import { TransferModal } from '../components/TransferModal/TransferModal'
import { Morph } from './Morph/Morph'
import { Splash } from './Splash'
import Adopt from './Adopt'

const Router: React.FC = () => (
  <Switch>
    <Route exact path="/">
      <Splash/>
    </Route>
    <Route path="/tokens">
      <Dashboard/>
    </Route>
    <Route path="/adopt">
      <Adopt/>
    </Route>
    <Route path="/morph/:hash">
      <Morph/>
    </Route>
  </Switch>
)

const AppWrapper: React.FC = ({ children }: PropsWithChildren<unknown>) => (
  <StickyFooter
    footer={<Footer/>}
  >
    <Header/>
    <Box py={3}/>
    {children}
  </StickyFooter>
)

const App: React.FC = () => {
  return (
    <Suspense fallback={null}>
      <WatcherProvider>
        <BrowserRouter>
          <TransferModal/>
          <AppWrapper>
            <Web3ReactManager>
              <Router/>
            </Web3ReactManager>
          </AppWrapper>
        </BrowserRouter>
      </WatcherProvider>
    </Suspense>
  )
}

export default App

