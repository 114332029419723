import React from 'react'
import {
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Icon,
  shortenAddress,
  Badge
} from '@djrhails/watcher-ui'
import { useDispatch } from 'react-redux'
import { nextStage } from '../../../state/transfer/action'
import { networkToVariantColor } from '../../../constants/Network'
import { ChainId, chainToNetwork, chainToPlatform, CHAIN_LABELS } from '../../../constants/ChainId'
import { TransferStage } from '../../../state/transfer/state'
import { useENSName } from '../../../hooks/useENSName'
import { parseAssetKey } from '../../../state/assets/action'
import { TransferProps } from '../TransferModal'
import { startCase } from 'lodash'
import { TransferArrow } from './TransferArrow'

interface PlatformIconProps {
  chainId: ChainId;
}
export function PlatformIcon({ chainId }: PlatformIconProps): React.ReactElement {
  const color = networkToVariantColor(chainToNetwork(chainId as ChainId))
  return (
    <Flex
      direction="column"
      align="center"
      grow={1}
    >
      <Icon
        size="4rem"
        color={color}
        name={startCase(chainToPlatform(chainId))}
      />
      <Badge
        my={3}
        variantColor={color}
      >{CHAIN_LABELS[chainId]}</Badge>
    </Flex>
  )
}

export function SummariseStep({ info, libs }: TransferProps): React.ReactElement {
  const dispatch = useDispatch()
  const next = () => {
    dispatch(nextStage({
      originChainId: info.originChainId,
      assetKey: info.assetKey,
      stage: TransferStage.FINDING_COMMITTEE,
      libs,
    }))
  }
  const assetId = parseAssetKey(info.assetKey ?? '')
  const ENSName = useENSName(assetId.trackerId ?? undefined)

  return (
    <>
      <ModalBody>
        <Flex width={1} py={5} px={3} justify="space-between">
          <PlatformIcon chainId={info.originChainId} />
          <TransferArrow/>
          <PlatformIcon chainId={info.destinationChainId as ChainId} />
        </Flex>
        <Flex width={1} justify="center">
          {chainToPlatform(info.originChainId) == 'eth' && assetId.innerId && (
            <>
            Transferring asset &#35;{parseInt(assetId.innerId)} from contract {ENSName || shortenAddress(assetId.trackerId)}
            </>
          )}
          {chainToPlatform(info.originChainId) == 'eth' && !assetId.innerId && (
            <>
            Transferring {info.amount ?? 1} from contract {ENSName || shortenAddress(assetId.trackerId)}
            </>
          )}
          {chainToPlatform(info.originChainId) == 'algo' && assetId.innerId && (
            <>
            Transferring from asset ID &#35;{assetId.trackerId}
            </>
          )}
        </Flex>
      </ModalBody>

      <ModalFooter justify="center">
        <Button
          buttonColor={networkToVariantColor(chainToNetwork(info.originChainId as ChainId))}
          onClick={next}
        >
          Select Signing Committee
        </Button>
      </ModalFooter>
    </>
  )
}
