import { Interface } from '@ethersproject/abi'
import EIP721_ABI from './eip721.json'
import EIP721_ENUM_ABI from './eip721_enum.json'
import EIP721_META_ABI from './eip721_meta.json'

const EIP721_INTERFACE = new Interface(EIP721_ABI)
const EIP721_ENUM_INTERFACE = new Interface(EIP721_ENUM_ABI)
const EIP721_META_INTERFACE = new Interface(EIP721_META_ABI)


export default EIP721_INTERFACE
export {
  EIP721_INTERFACE,
  EIP721_ABI,
  EIP721_ENUM_INTERFACE,
  EIP721_ENUM_ABI,
  EIP721_META_INTERFACE,
  EIP721_META_ABI
}

// ERC721 ENUMERABLE = 0x780e9d63

// bytes4(keccak256('totalSupply()')) == 0x18160ddd
// bytes4(keccak256('tokenOfOwnerByIndex(address,uint256)')) == 0x2f745c59
// bytes4(keccak256('tokenByIndex(uint256)')) == 0x4f6ccce7


// ERC721 METADATA = 0x5b5e139f

// bytes4(keccak256('name()')) == 0x06fdde03
// bytes4(keccak256('symbol()')) == 0x95d89b41
// bytes4(keccak256('tokenURI(uint256)')) == 0xc87b56dd


// ERC721 = 0x80ac58cd

// bytes4(keccak256('balanceOf(address)')) == 0x70a08231
// bytes4(keccak256('ownerOf(uint256)')) == 0x6352211e
// bytes4(keccak256('approve(address,uint256)')) == 0x095ea7b3
// bytes4(keccak256('getApproved(uint256)')) == 0x081812fc
// bytes4(keccak256('setApprovalForAll(address,bool)')) == 0xa22cb465
// bytes4(keccak256('isApprovedForAll(address,address)')) == 0xe985e9c5
// bytes4(keccak256('transferFrom(address,address,uint256)')) == 0x23b872dd
// bytes4(keccak256('safeTransferFrom(address,address,uint256)')) == 0x42842e0e
// bytes4(keccak256('safeTransferFrom(address,address,uint256,bytes)')) == 0xb88d4fde