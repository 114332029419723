import React from 'react'
import { Button, Grid, List, Container, Heading, Flex, Text, ListItem } from '@djrhails/watcher-ui'
import { Link } from 'react-router-dom'
import { SuperFormula } from '../components/SuperFormula'
import { createDict } from '../constants/ChainId'

export function Splash(): React.ReactElement {
  return (
    <Container>
      <Flex>
        <Flex px={4} direction="column">
          <Heading.h1 fontWeight="bold">isomorph</Heading.h1>
          <Heading.h4 fontWeight="light" pb={8}>isomorph makes it easy to transfer assets between blockchains, all without collateral.</Heading.h4>
          <Flex>
            <Link to="/tokens">
              <Button>Launch dApp</Button>
            </Link>
            <Link to="/adopt">
              <Button buttonColor="accent" ml={3}>Adopt a MORPH</Button>
            </Link>
          </Flex>
        </Flex>
        <SuperFormula animated />
      </Flex>

      <Flex py={12} width={1} justify="space-between">
        <Flex direction="column" width={1 / 2}>
          <Heading.h3>Why transfer?</Heading.h3>
          <Text py={3}>isomorph allows two economically sovereign and technological diverse chains to share fundamental assets across chain.</Text>
          <List spacing={3} my={4}>
            <ListItem variant="check">
              Leverage performance and price on one blockchain.
            </ListItem>
            <ListItem variant="check">
              Leverage community and ecosystem on another.
            </ListItem>
            <ListItem variant="check">
              Keep all your existing metadata.
            </ListItem>
          </List>
        </Flex>
        <Grid columns={[1, 1, 3]} width={1 / 2} spacing={4}>
          <Flex
            direction="column"
            align="center"
            justify="center"
            bg="#f0f5f9"
            borderRadius="md"
            py={4}
          >
            <Text
              fontSize={5}
              display="inline"
              variant="gradient"
              backgroundImage="linear-gradient(63.75deg,#f12711,#f5af19)"
            >{Object.keys(createDict(1)).length}</Text>
            <Text fontSize={0} uppercase>
              Chains
            </Text>
          </Flex>
          <Flex
            direction="column"
            align="center"
            justify="center"
            bg="#f0f5f9"
            borderRadius="md"
            py={4}
          >
            <Text
              fontSize={5}
              display="inline"
              variant="gradient"
              backgroundImage="linear-gradient(63.75deg,#f12711,#f5af19)"
            >N</Text>
            <Text fontSize={0} uppercase>
              Active Tokens
            </Text>
          </Flex>
          <Flex
            direction="column"
            align="center"
            justify="center"
            bg="#f0f5f9"
            borderRadius="md"
            py={4}
          >
            <Text
              fontSize={5}
              display="inline"
              variant="gradient"
              backgroundImage="linear-gradient(63.75deg,#f12711,#f5af19)"
            >1</Text>
            <Text fontSize={0} uppercase>
              Committee Members
            </Text>
          </Flex>
        </Grid>
      </Flex>
    </Container>
  )
}
