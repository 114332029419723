import React from 'react'
import { Container } from '@djrhails/watcher-ui'
import { useParams } from 'react-router-dom'
import { SuperFormula } from '../../components/SuperFormula'
import { parseParams } from '../../components/SuperFormula/functions'

export const Morph: React.FC = () => {
  const { hash } = useParams<{ hash: string }>()
  const withoutSignifiers = hash.replace('0x', '')
  return <Container as="section">
    <SuperFormula parameters={parseParams(withoutSignifiers)}/>
  </Container>
}
