import React from 'react'
import {
  ModalBody,
  ModalFooter,
  Spinner,
  Text,
  Flex,
} from '@djrhails/watcher-ui'
import { Network } from '../../constants'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../connectors'
import { ActivateModalProps, MethodProps } from '.'
import { ActivateModal } from './ActivateModal'

function MetaMask({ onClose }: MethodProps): React.ReactElement {
  const { activate } = useWeb3React(Network.Ethereum)

  React.useEffect(() => {
    activate(injected, undefined, true).then(() => {
      onClose()
    })
  }, [activate, onClose])

  return (
    <>
      <ModalBody>
        <Flex align="center" direction="column">
          <Spinner/>
          <Text my={4}>
        Waiting for MetaMask connection
          </Text>
        </Flex> 
      </ModalBody>
      <ModalFooter/>
    </>
  )
}

export function EthereumActivateModal({
  isOpen,
  onClose,
}: ActivateModalProps): React.ReactElement {
  const ethereumMethods = {
    'MetaMask': MetaMask,
  }

  return <ActivateModal
    isOpen={isOpen}
    onClose={onClose}
    network={Network.Ethereum}
    methods={ethereumMethods}
  />
}
