import React, { useEffect, useState } from 'react'
import {
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Text,
  Committee,
  CommitteeProps
} from '@djrhails/watcher-ui'
import { useDispatch } from 'react-redux'
import { nextStage } from '../../../state/transfer/action'
import { networkToVariantColor } from '../../../constants/Network'
import { ChainId, chainToNetwork } from '../../../constants/ChainId'
import { TransferProps } from '../TransferModal'
import { TransferStage } from '../../../state/transfer/state'

export function VerifyStep({ info, libs }: TransferProps): React.ReactElement {
  const dispatch = useDispatch()
  const [ready, setReady] = useState<boolean>(false)
  const next = () => {
    dispatch(nextStage({
      originChainId: info.originChainId,
      assetKey: info.assetKey,
      stage: info.returning ? TransferStage.RELEASED : TransferStage.PAIR_SIGNED,
      libs,
    }))
  }

  useEffect(() => {
    if (info.signingCommittee?.reduce((acc, mem) => acc && mem.approved, true)) {
      setReady(true)
    }
  }, [info.signingCommittee])

  const members: CommitteeProps['members'] = (info.signingCommittee ?? []).map((member) => {
    const rating = member.approved ? 'approve' : 'pending'
    return {
      name: member.did,
      address: member.did,
      rating: rating, 
    }
  })

  return (
    <>
      <ModalBody>
        <Text fontWeight="semibold" pb={2}>SIGNING COMMITTEE</Text>
        <Flex width="full" height="20rem">
          <Committee
            members={members}
            committeeDiameter={200} // ~13rem
            padding={40}
            withFlags
          />
        </Flex>
            Your randomly selected committee will now authenticate your transfer. They can only verify a {info.returning ? 'burn' : 'lock-up'}, and {info.returning ? 'release' : 'sign'} the corresponding asset.
      </ModalBody>

      <ModalFooter justify="center">
        <Button
          opacity={ready ? 1 : 0.5}
          buttonColor={networkToVariantColor(chainToNetwork(info.destinationChainId as ChainId))}
          onClick={next}
        >
          {info.returning && (ready ? 'Inspect your returned Asset' : 'Waiting for Signing Committee')}
          {!info.returning && (ready ? 'Inspect your new ~Asset' : 'Waiting for Signing Committeee')}
        </Button>
      </ModalFooter>
    </>
  )
}
