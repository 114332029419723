import React from 'react'
import {
  Text,
  ModalBody,
  ModalFooter,
  Button,
  Link,
  Grid
} from '@djrhails/watcher-ui'
import { Network } from '../../constants'
import { toHumanName, networkToColor } from '../../constants/Network'

interface SelectMethodProps {
  methods: string[];
  setMethod: (method: string) => void;
  network: Network;
}

const toWalletLink = (network: Network): string => {
  return {
    [Network.Ethereum]: 'https://ethereum.org/wallets',
    [Network.EthereumFallback]: 'https://ethereum.org/wallets',
    [Network.Algorand]: 'https://wallet.myalgo.com/', 
  }[network]
}

export function SelectMethod({ methods, setMethod, network }: SelectMethodProps): React.ReactElement {
  return (
    <>
      <ModalBody>
        <Grid columns={[1, null, 3]} spacing={5}>
          {methods.map((method) => (
            <Button
              key={method}
              buttonColor={networkToColor(network)}
              variant="outline"
              onClick={() => setMethod(method)}
            >{method}</Button>
          ))}
        </Grid>
      </ModalBody>

      <ModalFooter>
        <Text>
          New to {toHumanName(network)}? <Link color={networkToColor(network)} target="_blank" rel="noopener noreferrer" href={toWalletLink(network)}>Learn more about wallets</Link>
        </Text>
      </ModalFooter>
    </>
  )
}
