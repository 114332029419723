import { createAction } from '@reduxjs/toolkit'
import { ETH_ADDRESS_REGEX, LOWER_HEX_REGEX } from '../../constants'

export interface Call {
  address: string;
  callData: string;
}

export function toCallKey(call: Call): string {
  if (!ETH_ADDRESS_REGEX.test(call.address)) {
    throw new Error(`Invalid ethereum address: ${call.address}`)
  }
  if (!LOWER_HEX_REGEX.test(call.callData)) {
    throw new Error(`Invalid hex: ${call.callData}`)
  }
  return `${call.address}-${call.callData}`
}

export function parseCallKey(callKey: string): Call {
  const callParts = callKey.split('-')
  if (callParts.length !== 2) {
    throw new Error(`Invalid call key: ${callKey}`)
  }
  return {
    address: callParts[0],
    callData: callParts[1]
  }
}

export interface ListenerOptions {
  // how often this data should be fetched, by default 1
  readonly blocksPerFetch?: number;
}

export const addMulticallListeners = createAction<{ chainId: number; calls: Call[]; options?: ListenerOptions }>(
  'multicall/addListeners'
)
export const removeMulticallListeners = createAction<{ chainId: number; calls: Call[]; options?: ListenerOptions }>(
  'multicall/removeListeners'
)
export const fetchingMulticallResults = createAction<{ chainId: number; calls: Call[]; fetchingBlockNumber: number }>(
  'multicall/fetchingResults'
)
export const errorFetchingMulticallResults = createAction<{
  chainId: number;
  calls: Call[];
  fetchingBlockNumber: number;
}>('errorFetchingMulticallResults')
export const updateMulticallResults = createAction<{
  chainId: number;
  blockNumber: number;
  results: {
    [callKey: string]: string | null;
  };
}>('multicall/updateResults')
