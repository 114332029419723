import { SuperFormula } from './SuperFormula'

export interface SuperFormulaParameters {
  a: number;
  b: number;
  m: number;
  n1: number;
  n2: number;
  n3: number;
  iterations?: number;
  decay?: number;
}

export interface Point {
  x: number;
  y: number;
}

export type Path = Point[];

export interface SuperFormulaProps {
  parameters?: SuperFormulaParameters;
  radius?: number;
  resolution?: number;
  animated?: true;
}

export { SuperFormula }

export default SuperFormula