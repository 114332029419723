import { ChainId } from '../ChainId'
import MORPH_ABI from './morph.json'

const MORPH_ADDRESSES: { [chainId in ChainId]: string | null } = {
  [ChainId.ETH_MAINNET]: null,
  [ChainId.ETH_ROPSTEN]: null,
  [ChainId.ETH_KOVAN]: null,
  [ChainId.ETH_RINKEBY]: '0x25634DF5d85aE48f3D2ed34Dc4870d618BAd7915',
  [ChainId.ETH_GÖRLI]: null,
  [ChainId.ALGO_MAIN]: null,
  [ChainId.ALGO_BETA]: null,
  [ChainId.ALGO_TEST]: null
}

export { MORPH_ABI, MORPH_ADDRESSES }
