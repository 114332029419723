import { createReducer } from '@reduxjs/toolkit'
import { validPairs } from '../../constants/ChainId'
import { updateTransfer, abortTransfer, startTransfer } from './action'
import { TransferStage, TransferState } from './state'

export const initialState: TransferState = {
  transfers: {},
}

export default createReducer(initialState, builder =>
  builder
    .addCase(startTransfer, (state, action) => {
      const { originChainId, assetKey, amount, isopair } = action.payload

      // Set active state
      state.active = {
        chainId: originChainId,
        assetKey: assetKey,
      }
      
      // Set transfer to started
      const transfers = state.transfers ? state.transfers : (state.transfers = {})
      const chain = transfers[originChainId] ? transfers[originChainId] : (transfers[originChainId] = {})

      chain[assetKey] = {
        assetKey: assetKey,
        originChainId: originChainId,
        destinationChainId: validPairs(originChainId)[0],
        amount: amount ?? 1,
        stage: TransferStage.START,
        pairedAssetKey: isopair,
        returning: isopair !== undefined,
      }
    })
    .addCase(updateTransfer, (state, action) => {
      const { originChainId, assetKey, info } = action.payload

      const transfers = state.transfers ? state.transfers : (state.transfers = {})
      const chain = transfers[originChainId] ? transfers[originChainId] : (transfers[originChainId] = {})

      chain[assetKey] = {
        ...chain[assetKey],
        ...info,
      }
    })
    .addCase(abortTransfer, (state, _) => {
      state.active = undefined
    })
)