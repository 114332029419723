import { EIP20, EIP721 } from '../constants/tokens'
import { useMemo } from 'react'
import EIP20_INTERFACE from '../constants/abis/eip20'
import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { EIP721_INTERFACE } from '../constants/abis/eip721'

type Balances = { [tokenAddress: string]: BigNumber }
type BalancesWithLoading = [Balances, boolean]

export function useEIP721BalancesWithLoading(
  address?: string,
  trackers?: EIP721[],
  allTokens = false,
): BalancesWithLoading {

  const tokenAddresses = useMemo(() => trackers?.map(tracker => tracker.trackerId), [trackers])

  const balances = useMultipleContractSingleData(tokenAddresses ?? [], EIP721_INTERFACE, 'balanceOf', [address])

  const anyLoading = balances.some(callState => callState.loading)

  return [
    useMemo(
      () =>
        address && trackers && trackers.length > 0
          ? trackers.reduce<Balances>((memo, token, i) => {
            const value = balances?.[i]?.result?.[0]
            if (value && (allTokens || !value.isZero())) {
              memo[token.trackerId] = value
            }
            return memo
          }, {})
          : {},
      [address, trackers, balances, allTokens]
    ),
    anyLoading
  ]
}

export function useEIP20BalancesWithLoading(
  address?: string,
  trackers?: EIP20[],
  allTokens = false,
): BalancesWithLoading {

  const tokenAddresses = useMemo(() => trackers?.map(vt => vt.trackerId), [trackers])
  const balances = useMultipleContractSingleData(tokenAddresses ?? [], EIP20_INTERFACE, 'balanceOf', [address])

  const anyLoading = balances.some(callState => callState.loading)

  return [
    useMemo(
      () => address && trackers && trackers.length > 0
        ? trackers.reduce<{ [tokenAddress: string]: BigNumber} >(
          (memo, token, i) => {
            const value = balances?.[i]?.result?.[0]
            if (value && (allTokens || !value.isZero())) {
              memo[token.trackerId] = value
            }
            return memo
          }, {})
        : {},
      [address, trackers, balances, allTokens]
    ),
    anyLoading
  ]
}