import { useActiveEthereum } from '../useActiveEthereum'
import { Contract } from '@ethersproject/contracts'
import { useContract } from '.'
import { MULTICALL_ADDRESSES, MULTICALL_ABI } from '../../constants/abis/multicall'

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveEthereum()
  const multiCallAddress = chainId 
    ? MULTICALL_ADDRESSES[chainId] ?? undefined
    : undefined
  return useContract(multiCallAddress, MULTICALL_ABI, false)
}
