import { ChainId } from '../ChainId'
import MULTICALL_ABI from './multicall.json'

const MULTICALL_ADDRESSES: { [chainId in ChainId]: string | null } = {
  [ChainId.ETH_MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ETH_ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.ETH_KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.ETH_RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.ETH_GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.ALGO_MAIN]: null,
  [ChainId.ALGO_BETA]: null,
  [ChainId.ALGO_TEST]: null
}

export { MULTICALL_ABI, MULTICALL_ADDRESSES }
