import React from 'react'
import { SVG } from './SVG'
import { randomParams, decayParams } from './functions'
import { SuperFormulaProps, SuperFormulaParameters } from './index'

const TICK_RATE = 50
const MORPH_RATE = 0.02
const SWITCH_RATE = 10000

export const SuperFormula: React.FC<SuperFormulaProps> = ({
  animated,
  parameters,
  radius = 50,
  resolution = 720,
}: SuperFormulaProps) => {
  const [direction, setDirection] = React.useState<boolean>(false)
  const [params, setParams] = React.useState<SuperFormulaParameters>()

  React.useEffect(() => setParams(parameters || randomParams()), [parameters])

  React.useEffect(() => {
    if (!animated) {
      return
    }
    const interval = setInterval(() => {
      setParams((param?: SuperFormulaParameters) => param ? decayParams(1, param, direction ? MORPH_RATE : -MORPH_RATE) : randomParams())
    }, TICK_RATE)
    return () => clearInterval(interval)
  },[direction, animated])

  React.useEffect(() => {
    if (!animated) {
      return
    }

    const directionSwitcher = setInterval(() => {
      setDirection((direction: boolean) => !direction)
    }, SWITCH_RATE)
    return () => clearInterval(directionSwitcher) 
  }, [animated])

  if (!params) {
    return null
  }

  return <SVG radius={radius} params={params} resolution={resolution} />
}
