export function makeCancellable<T>(promise: Promise<T>): { promise: Promise<T>; cancel: VoidFunction } {
  let cancelled = false

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise
      .then(
        val => (cancelled ? reject(new Error(`Promise was cancelled but returned: ${val}`)) : resolve(val))
      )
      .catch(
        error => cancelled 
          ? reject(new Error(`Promise was cancelled but errored with: ${error}`))
          : reject(error)
      )
  })

  return {
    promise: wrappedPromise,
    cancel(): void {
      cancelled = true
    }
  }
}