import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { NetworkV2Connector} from './NetworkV2Connector'

import { ChainId } from '../constants'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

if (typeof NETWORK_URL === 'undefined') {
  throw new Error('REACT_APP_NETWORK_URL must be a defined environment variable')
}

export const network = new NetworkConnector({
  urls: { [Number(process.env.REACT_APP_CHAIN_ID)]: NETWORK_URL }
})

export const injected = new InjectedConnector({
  supportedChainIds: [
    ChainId.ETH_MAINNET,
    ChainId.ETH_ROPSTEN,
    ChainId.ETH_RINKEBY,
    ChainId.ETH_GÖRLI,
    ChainId.ETH_KOVAN
  ]
})

// export const purestake = new NetworkV2Connector({
//   urls: {
//     [ChainId.ALGO_TEST]: 'https://testnet-algorand.api.purestake.io/ps2',
//     [ChainId.ALGO_MAIN]: 'https://mainnet-algorand.api.purestake.io/ps2',
//     [ChainId.ALGO_BETA]: 'https://betanet-algorand.api.purestake.io/ps2'
//   },
//   indexers: {
//     [ChainId.ALGO_TEST]: 'https://testnet-algorand.api.purestake.io/idx2',
//     [ChainId.ALGO_MAIN]: 'https://mainnet-algorand.api.purestake.io/idx2',
//     [ChainId.ALGO_BETA]: 'https://betanet-algorand.api.purestake.io/idx2'
//   },
//   defaultChainId: ChainId.ALGO_TEST,
//   apiKey: process.env.REACT_APP_PURE_STAKE_API_KEY ?? ''
// })

export const algoexplorer = new NetworkV2Connector({
  urls: {
    [ChainId.ALGO_TEST]: 'https://api.testnet.algoexplorer.io',
    [ChainId.ALGO_MAIN]: 'https://api.mainnet.algoexplorer.io',
    [ChainId.ALGO_BETA]: 'https://api.betanet.algoexplorer.io'
  },
  indexers: {
    [ChainId.ALGO_TEST]: 'https://api.testnet.algoexplorer.io/idx2',
    [ChainId.ALGO_MAIN]: 'https://api.algoexplorer.io/idx2',
    [ChainId.ALGO_BETA]: 'https://api.betanet.algoexplorer.io/idx2'
  },
  defaultChainId: ChainId.ALGO_TEST,
})