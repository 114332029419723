import React from 'react'
import {
  ModalBody,
  ModalFooter,
  Button,
  Heading
} from '@djrhails/watcher-ui'
import { TransferProps } from '../TransferModal'

export interface ErrorStepProps extends TransferProps {
  onClose(): void;
}
export function ErrorStep({ info, onClose }: ErrorStepProps): React.ReactElement {
  return (
    <>
      <ModalBody>
        <Heading.h4 pb={5}>An error occured, we cannot transfer your Asset</Heading.h4>
        <code>
          {info?.error}
        </code>
      </ModalBody>

      <ModalFooter justify="center">
        <Button
          onClick={onClose}
        >
          Retry
        </Button>
      </ModalFooter>
    </>
  )
}
