import React from 'react'
import { SuperFormulaParameters } from './index'
import { Path } from './Path'
import { Gradient } from './Gradient'
import { superFormulaPaths, scalePaths, path2SVGInstruction } from './functions'

interface SVGProps {
  params: SuperFormulaParameters;
  resolution: number;
  radius: number;
}

export const SVG: React.FC<SVGProps> = ({ params, resolution, radius }: SVGProps) => {
  const viewBox = `-${radius} -${radius} ${2 * radius} ${2 * radius}`

  const [paths, setPaths] = React.useState<string[]>([])

  React.useEffect(() => {
    const paths = superFormulaPaths(resolution, params)
    const scaledPaths = scalePaths(radius, paths)
    const svgPaths = scaledPaths.map(path2SVGInstruction)
    setPaths(svgPaths)
  }, [params, resolution, radius])

  return (
    <svg width="100%" height="100%" viewBox={viewBox}>
      <Gradient/>
      {paths.map((path: string, idx: number) => <Path key={idx} path={path} />)}
    </svg>
  )
}
