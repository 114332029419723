import type { ChainDict } from '../index'
import { EIP20 } from './EIP20'
import { EIP721 } from './EIP721'
import { ASA } from './ASA'
import { FungibleToken, NonfungibleToken } from './Token'
import { KovanEIP20 } from './ethereum/kovan'
import { MainnetEIP20, MainnetEIP721} from './ethereum/mainnet'
import { RinkebyEIP20, RinkebyEIP721 } from './ethereum/rinkeby'
import { RopstenEIP20 } from './ethereum/ropsten'
import { createDict, ChainId } from '../ChainId'
import { TrackerData } from '../../state/assets/reducer'

type AllTokens<D> = ChainDict<Readonly<{ [tokenAddress: string]: D }>>

export { EIP20, EIP721, ASA, FungibleToken, NonfungibleToken }

export const WETH = {
  [ChainId.ETH_MAINNET]: new EIP20(
    ChainId.ETH_MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ETH_ROPSTEN]: new EIP20(
    ChainId.ETH_ROPSTEN,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ETH_RINKEBY]: new EIP20(
    ChainId.ETH_RINKEBY,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.ETH_GÖRLI]: new EIP20(ChainId.ETH_GÖRLI, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.ETH_KOVAN]: new EIP20(ChainId.ETH_KOVAN, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped Ether')
}

export const ALL_FUNGIBLE_TOKENS: AllTokens<EIP20> = [
  ...Object.values(WETH),
  ...MainnetEIP20,
  ...RinkebyEIP20,
  ...KovanEIP20,
  ...RopstenEIP20
].reduce<AllTokens<EIP20>>(
  (tokenMap, token) => {
    if (tokenMap[token.chainId][token.trackerId] !== undefined) throw Error('Duplicate tokens.')
    return {
      ...tokenMap,
      [token.chainId]: {
        ...tokenMap[token.chainId],
        [token.trackerId]: token
      }
    }
  },
  createDict<Readonly<{ [tokenAddress: string]: EIP20 }>>({})
)

export const ALL_NONFUNGIBLE_TOKEN: AllTokens<EIP721> = [
  ...MainnetEIP721,
  ...RinkebyEIP721,
].reduce<AllTokens<EIP721>>(
  (tokenMap, token) => {
    if (tokenMap[token.chainId][token.trackerId] !== undefined) throw Error('Duplicate tokens.')
    return {
      ...tokenMap,
      [token.chainId]: {
        ...tokenMap[token.chainId],
        [token.trackerId]: token
      }
    }
  },
  createDict<Readonly<{ [tokenAddress: string]: EIP721 }>>({})
)

export const ALL_TRACKERS: AllTokens<TrackerData> = [
  ...Object.values(WETH),
  ...MainnetEIP20,
  ...RinkebyEIP20,
  ...KovanEIP20,
  ...RopstenEIP20,
  ...MainnetEIP721,
  ...RinkebyEIP721,
].reduce<AllTokens<TrackerData>>(
  (tokenMap, token) => {
    if (tokenMap[token.chainId][token.trackerId] !== undefined) throw Error('Duplicate tokens.')
    return {
      ...tokenMap,
      [token.chainId]: {
        ...tokenMap[token.chainId],
        [token.trackerId]: {
          trackerId: token.trackerId,
          name: token.name ?? '',
          decimals: token.decimals,
          symbol: token.symbol,
          total: token.total,
          creator: token.trackerId,
        }
      }
    }
  },
  createDict<Readonly<{ [tokenAddress: string]: TrackerData }>>({})
)