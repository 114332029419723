import React from 'react'
import { Icon } from '@djrhails/watcher-ui'

export function TransferArrow(): React.ReactElement {
  return (
    <Icon size="4rem" name="TrendingFlat" color="gray" sx={{
      flex: '0 0 auto',
      px: 3,
    }}/>
  )
}
