import React, { ReactNode } from 'react'
import {
  Text,
  Flex
} from '@djrhails/watcher-ui'
interface BlockProps {
  title: string;
  children?: ReactNode;
}
export const Block: React.FC<BlockProps> = ({ title, children }: BlockProps) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    bg="#f0f5f9"
    borderRadius="md"
    py={4}
  >
    <Text fontSize={0} uppercase>
      {title}
    </Text>
    {children}
  </Flex>
)
