import { EIP20 } from '../EIP20'
import { EIP721 } from '../EIP721'
import { ChainId } from '../../ChainId'

export const RinkebyEIP20 = [
  new EIP20(ChainId.ETH_RINKEBY, '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735', 18, 'DAI', 'Dai Stablecoin'),
  new EIP20(ChainId.ETH_RINKEBY, '0xF9bA5210F91D0474bd1e1DcDAeC4C58E359AaD85', 18, 'MKR', 'Maker'),
  new EIP20(ChainId.ETH_RINKEBY, '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984', 18, 'UNI', 'Uniswap'),
]

export const RinkebyEIP721 = [
  // new EIP721(ChainId.ETH_RINKEBY, '0x3126783d1471e9ec9B9e322F19f3A0b50d8Af2dA', 'CARGO', 'Rinkeby Cargo Collection'),
  // new EIP721(ChainId.ETH_RINKEBY, '0x01Eb6486CCC0648D1C407bB3F13B7a149Ff6559a', 'ART', 'ARRtistry Artifact'),
  new EIP721(ChainId.ETH_RINKEBY, '0x25634DF5d85aE48f3D2ed34Dc4870d618BAd7915', 'MORPH', 'Morph'),
]