export enum Network {
  Ethereum = 'ETHEREUM_NETWORK',
  EthereumFallback = 'ETHEREUM_FALLBACK',
  Algorand = 'ALGORAND_NETWORK',
}

export type Platform = 'eth' | 'algo';

export const toPlatform = (network: Network): Platform => {
  return {
    [Network.Ethereum]: 'eth' as const,
    [Network.EthereumFallback]: 'eth' as const,
    [Network.Algorand]: 'algo' as const, 
  }[network]
}

export const invertPlatform = (platform: Platform): Platform => platform === 'eth' ? 'algo' : 'eth'

export const toColor = (platform: Platform): string => {
  return platform === 'eth' ? '#f12711' : '#f5af19' 
}

export const toVariantColor = (platform: Platform): 'brand' | 'accent' => {
  return platform === 'eth' ? 'brand' : 'accent'
}

export const invertNetwork = (network: Network): Network => {
  return {
    [Network.Ethereum]: Network.Algorand,
    [Network.EthereumFallback]: Network.EthereumFallback,
    [Network.Algorand]: Network.Ethereum, 
  }[network]
}

export const networkToColor = (network: Network): string => {
  return toColor(toPlatform(network))
}

export const networkToVariantColor = (network: Network): 'brand' | 'accent' => {
  return toVariantColor(toPlatform(network))
}

export const toHumanName = (network: Network): string => {
  return {
    [Network.Ethereum]: 'Ethereum',
    [Network.EthereumFallback]: 'Hidden Ethereum',
    [Network.Algorand]: 'Algorand', 
  }[network]
}