import React from 'react'
import { Text } from '@djrhails/watcher-ui'
import { networkToColor, Network } from '../../constants/Network'
interface GradientChainSplitProps {
  split: number;
}
export const GradientChainSplit: React.FC<GradientChainSplitProps> = (props: GradientChainSplitProps) => {
  const { split } = props
  const percentage = (split * 100).toFixed(0)
  // TODO: Don't hardcode colours here, it's a bit nasty.

  return <Text
    variant="gradient"
    fontSize={4}
    fontWeight="bold"
    display="inline"
    backgroundImage={`linear-gradient(.25turn, ${networkToColor(Network.Ethereum)}, ${percentage}%, ${networkToColor(Network.Algorand)})`}
  >
    {percentage}%
  </Text>
}
