import React from 'react'
import {
  Asset,
  Button,
  Flex,
  Icon
} from '@djrhails/watcher-ui'

import { TokenDisplay } from '../../pages/Dashboard/TokenSection'
import { BigNumber, formatFixed } from '@ethersproject/bignumber'
import { invertPlatform, toVariantColor } from '../../constants/Network'

interface FlippedAssetRowProps extends TokenDisplay {
  flipped: boolean;
  onClick: VoidFunction;
  approximate?: boolean;
  locked?: boolean;
}

export const FlippedAssetRow: React.FC<FlippedAssetRowProps> = ({
  token,
  platform,
  approximate,
  locked,
  flipped,
  onClick,
}: FlippedAssetRowProps) => {
  const pp = platform === 'eth' ? 'eth' : 'algo'
  const colors = {
    startColor: toVariantColor(pp),
    endColor: toVariantColor(invertPlatform(pp)),
  }
  const press = () => {
    if (!locked) {
      onClick()
    }
  }
  
  return (
    <tr>
      <td>
        <Asset
          name={token.tracker?.name ?? 'Nameless'}
          ticker={token.tracker?.symbol ?? 'N/A'}
          platform={platform}
          full
          flipped={flipped || undefined} />
      </td>
      <td>{formatFixed(BigNumber.from(token.asset?.balance), token.tracker?.decimals)}</td>
      <td>
        <Button variant="gradient" {...colors} onClick={press}>
          <Flex>
            {locked && <Icon name="Lock" />}
            {approximate ? 'Return' : 'Transfer'}
          </Flex>
        </Button>
      </td>
    </tr>
  )
}
