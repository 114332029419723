import React, { useEffect, useState } from 'react'
import {
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Nifty,
  List,
  ListItem,
  TokenCard,
} from '@djrhails/watcher-ui'
import { useDispatch } from 'react-redux'
import { nextStage } from '../../../state/transfer/action'
import { networkToVariantColor } from '../../../constants/Network'
import { ChainId, chainToNetwork, chainToPlatform } from '../../../constants/ChainId'
import { TransferInfo, TransferStage } from '../../../state/transfer/state'
import { TransferProps } from '../TransferModal'
import styled from 'styled-components'
import { useToken, useNifty } from '../../../state/assets/hooks'
import { TransferArrow } from './TransferArrow'
import { BigNumber } from '@ethersproject/bignumber'

const TransparentNifty = styled(Nifty)<any>`
  opacity: ${({ live }) => live ? 1 : 0.70}
`

interface PairProps {
  info: TransferInfo;
  ready: boolean;
  setReady(ready: boolean): void; 
}

function NiftyPair({ info, ready, setReady }: PairProps): React.ReactElement {
  const { props: originProps } = useNifty(info.originChainId, info.assetKey)
  const [pairedProps, setPairedProps] = useState({
    chainId: info.originChainId,
    assetKey: info.assetKey,
  })

  useEffect(() => {
    if (!info.destinationChainId || !info.pairedAssetKey) {
      return
    }
    setPairedProps({
      chainId: info.destinationChainId,
      assetKey: info.pairedAssetKey,
    })
    setReady(true)
  }, [info.destinationChainId, info.pairedAssetKey])

  const { props: destProps, loading} = useNifty(pairedProps.chainId, pairedProps.assetKey)
  // TODO(djrhails): Dedupe with Dasboard/NFTSection/NiftyFromToken

  return <Flex width={1} direction="column">
    <Flex width={1} direction="row">
      <Nifty {...originProps} />
      <TransferArrow />
      <TransparentNifty
        {...destProps}
        platform={chainToPlatform(info.destinationChainId as ChainId)}
        trackerId={info.pairedAssetKey !== undefined ? destProps.trackerId : '???'}
        live={info.pairedAssetKey !== undefined}
      />
    </Flex>
    <Flex width={2/3} mx="auto" mt={8} >
      <List spacing={3}>
        <ListItem variant={ready ? 'check' : 'pending'}>
          Cloning asset metadata...
        </ListItem>
        <ListItem variant={ready ? 'check' : 'pending'}>
          Deploying new asset to destination chain...
        </ListItem>
        <ListItem variant={ready && !loading ? 'check' : 'pending'}>
          Retrieving Asset Metadata from destination chain...
        </ListItem>
      </List>
    </Flex>
  </Flex>
}

function TokenPair({ info, ready, setReady }: PairProps): React.ReactElement {
  const token = useToken(info.originChainId, info.assetKey)
  const [pairedProps, setPairedProps] = useState({
    chainId: info.originChainId,
    assetKey: info.assetKey,
  })

  useEffect(() => {
    if (!info.destinationChainId || !info.pairedAssetKey) {
      return
    }
    setPairedProps({
      chainId: info.destinationChainId,
      assetKey: info.pairedAssetKey,
    })
    setReady(true)
  }, [info.destinationChainId, info.pairedAssetKey])

  const pairedToken = useToken(pairedProps.chainId, pairedProps.assetKey)

  return <Flex width={1} direction="column">
    <Flex width={1} direction="row">
      <TokenCard
        width={1}
        mx={8}
        name={token.tracker?.symbol ?? 'Pending'}
        quantity={BigNumber.from(info.amount).toNumber() ?? 0}
        value={token.tracker?.price ?? -1}
      />
      <TransferArrow />
      <TokenCard
        width={1}
        mx={8}
        name={pairedToken.tracker?.symbol ?? 'Pending'}
        quantity={BigNumber.from(info.amount).toNumber() ?? 0}
        value={pairedToken.tracker?.price ?? -1}
      />
    </Flex>
    <Flex width={2/3} mx="auto" mt={8} >
      <List spacing={3}>
        <ListItem variant={ready ? 'check' : 'pending'}>
          Cloning asset metadata...
        </ListItem>
        <ListItem variant={ready ? 'check' : 'pending'}>
          Deploying new asset to destination chain...
        </ListItem>
      </List>
    </Flex>
  </Flex>
}


export function PairStep({ info, libs }: TransferProps): React.ReactElement {
  const dispatch = useDispatch()
  const [ready, setReady] = useState<boolean>(false)
  
  const next = () => {
    if (!ready) {
      return
    }
    dispatch(nextStage({
      originChainId: info.originChainId,
      assetKey: info.assetKey,
      stage: TransferStage.AWAITING_LOCKUP,
      libs,
    }))
  }

  const token = useToken(info.originChainId, info.assetKey)

  return (
    <>
      <ModalBody>
        <Flex width={1} py={5} px={3} justify="space-between">
          { token.tracker?.decimals === 0 && token.tracker?.total === 1
            ? <NiftyPair info={info} ready={ready} setReady={setReady}/>
            : <TokenPair info={info} ready={ready} setReady={setReady}/>
          }
        </Flex>
      </ModalBody>

      <ModalFooter justify="center">
        <Button
          opacity={ready ? 1 : 0.5}
          buttonColor={networkToVariantColor(chainToNetwork(info.originChainId as ChainId))}
          onClick={next}
        >
          {ready ? 'Lock up original asset' : 'Waiting for ~Asset'}
        </Button>
      </ModalFooter>
    </>
  )
}
