import React, { useEffect, useState } from 'react'
import {
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Nifty,
  List,
  ListItem,
} from '@djrhails/watcher-ui'
import { useDispatch } from 'react-redux'
import { nextStage } from '../../../state/transfer/action'
import { networkToVariantColor } from '../../../constants/Network'
import { ChainId, chainToNetwork } from '../../../constants/ChainId'
import { TransferInfo, TransferStage } from '../../../state/transfer/state'
import { TransferProps } from '../TransferModal'
import { useToken, useNifty } from '../../../state/assets/hooks'


interface PairProps {
  info: TransferInfo;
  ready: boolean;
  setReady(ready: boolean): void; 
}

function NiftyPair({ info, ready }: PairProps): React.ReactElement {
  const { props } = useNifty(info.originChainId, info.assetKey)

  return <Flex width={1} direction="column">
    <Flex width={1} direction="row">
      <Nifty {...props} />
    </Flex>
    <Flex width={2/3} mx="auto" mt={8} >
      <List spacing={3}>
        <ListItem variant={ready ? 'check' : 'pending'}>
          Awaiting Burn...
        </ListItem>
      </List>
    </Flex>
  </Flex>
}

function TokenPair({ info: _ }: PairProps): React.ReactElement {
  return <></>
}

export function BurnStep({ info, libs }: TransferProps): React.ReactElement {
  const dispatch = useDispatch()
  const [ready, setReady] = useState<boolean>(false)
  
  const next = () => {
    if (!ready) {
      return
    }
    dispatch(nextStage({
      originChainId: info.originChainId,
      assetKey: info.assetKey,
      stage: TransferStage.VERIFY_BURN,
      libs,
    }))
  }

  const token = useToken(info.originChainId, info.assetKey)

  useEffect(() => {
    if (token.asset?.burned) {
      setReady(true)
    }
  }, [token])

  return (
    <>
      <ModalBody>
        <Flex width={1} py={5} px={3} justify="space-between">
          { token.tracker?.decimals === 0 && token.tracker?.total === 1
            ? <NiftyPair info={info} ready={ready} setReady={setReady}/>
            : <TokenPair info={info} ready={ready} setReady={setReady}/>
          }
        </Flex>
      </ModalBody>

      <ModalFooter justify="center">
        <Button
          opacity={ready ? 1 : 0.5}
          buttonColor={networkToVariantColor(chainToNetwork(info.originChainId as ChainId))}
          onClick={next}
        >
          {ready ? 'Request Signing of Burned Asset' : 'Waiting for Burn'}
        </Button>
      </ModalFooter>
    </>
  )
}
