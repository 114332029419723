import { createAction } from '@reduxjs/toolkit'
import { ETH_ADDRESS_REGEX, ChainId } from '../../constants'
import invariant from 'tiny-invariant'
import { OwnedAssetInformation, AccountInformation, IndexerAsset } from 'algosdk'
import { AlgorandLibrary } from '../../providers/AlgorandWeb3Provider'
import { AppThunk } from '..'
import { AssetInfo, AssetState, TrackerData } from './reducer'
import { Web3Provider } from '@ethersproject/providers'
import { EIP721Metadata } from '../../constants/tokens/EIP721'
import { BigNumberish } from '@ethersproject/bignumber'
import { isUndefined } from '@djrhails/watcher-ui'

export interface AssetId {
  trackerId: string; // Ethereum Address / ASA ID
  innerId?: string;
}

export function toAssetKey(assetId: AssetId): string {
  invariant(ETH_ADDRESS_REGEX.test(assetId.trackerId)
  || /\d+/.test(assetId.trackerId), `Invalid Tracker Address: ${assetId.trackerId}`)
  if (!assetId.innerId) {
    return assetId.trackerId
  }
  return `${assetId.trackerId}-${assetId.innerId}`
}

export function parseAssetKey(assetKey: string): AssetId {
  const keyParts = assetKey.split('-')
  return {
    trackerId: keyParts[0],
    innerId: keyParts[1]
  }
}

interface ASAAssetPayload {
  chainId: ChainId;
  asset: OwnedAssetInformation & { id: string; owner: string }
}

interface EthAssetPayload {
  chainId: ChainId,
  trackerId: string,
  asset: {
    innerId?: string;
    owner: string;
    balance: BigNumberish;
    tokenUri?: string;
    data?: EIP721Metadata;
  },
}

interface UpdateAssetPayload {
  chainId: ChainId;
  assetKey: string;
  asset: Partial<AssetInfo>;
}

interface UpdateTrackerPayload {
  chainId: ChainId;
  trackerId: string;
  tracker: Partial<TrackerData>;
}

interface TrackerPayload {
  chainId: ChainId;
  tracker: TrackerData;
}

interface PricePayload {
  identifiers: {
    chainId: ChainId;
    trackerId: string;
  }[];
}

export const updateTotals = createAction<Partial<AssetState['totals']>>('asset/updateTotals')
export const updateAsset = createAction<UpdateAssetPayload>('asset/updateAsset')
export const updateTracker = createAction<UpdateTrackerPayload>('asset/updateTracker')

export const fetchAssetError = createAction<{ err: Error }>('asset/fetchAssetError')


export const addASA = createAction<ASAAssetPayload>('asset/addASA')
export const addASATracker = createAction<TrackerPayload>('asset/addASATracker')
export const addEthToken = createAction<EthAssetPayload>('asset/addEthToken')

export const loadEthereumNifty = (
  { trackerId, chainId, asset }: EthAssetPayload,
): AppThunk => async (dispatch): Promise<void> => {
  const assetKey = toAssetKey({ trackerId: trackerId, innerId: asset.innerId})

  dispatch(addEthToken({
    chainId,
    trackerId,
    asset
  }))

  if (asset.tokenUri) {
    const value = await fetch(asset.tokenUri)
    const data = await value.json()
    dispatch(updateAsset({
      chainId,
      assetKey: assetKey,
      asset: {
        data: data
      }
    }))
  }  
}

export const addPrices = (
  { identifiers }: PricePayload
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { assets } = getState()

  const lookup = []
  for (const { chainId, trackerId } of identifiers) {
    const tracker = assets.trackers[chainId][trackerId]
    if (tracker.price === undefined && tracker.symbol) {
      lookup.push(tracker.symbol)
      console.log('tracker.price: ', tracker.price)
    }
  }

  if (lookup.length == 0) {
    return
  }

  console.log('looking up: ', lookup)
  const quotes = await fetch(`https://api.isomorph.network/1/prices/${lookup.join(',')}`)
  const quotesJ = await quotes.json()
  
  for (const { chainId, trackerId } of identifiers) {
    const tracker = assets.trackers[chainId][trackerId]
    dispatch(updateTracker({
      chainId,
      trackerId,
      tracker: {
        price: quotesJ.data[tracker.symbol ?? '']?.quote.USD.price ?? 0.0,
      }
    }))
  }
}

// ({ chainId: library.getCurrentChain(), asset: {...asset, id: assetId, owner: address } })

export const fetchEthereumAssets = (
  _library: Web3Provider,
  _address: string,
  _chainId: ChainId,
): AppThunk => async (dispatch): Promise<void> => {
  try {
    // const { assets } = getState()
    // const _trackers = assets.trackers[chainId]


    // Get tracker balances

    // Get Token Enumeration for owned
    
    // Get token ids and URis


    // const nonZeroAssets = Object.entries<OwnedAssetInformation>(assets).filter(([_assetId, asset]) => asset.amount !== 0)

    // Promise.all(
    //   nonZeroAssets.map(async ([assetId, asset]) => {
    //     if (asset.amount !== 0) {
    //       const assetInfo = await library.getAssetInformation(assetId)
    //       dispatch(addASATracker({ chainId: library.getCurrentChain(), assetInfo: {...assetInfo, id: assetId }}))
    //       dispatch(addASA({ chainId: library.getCurrentChain(), asset: {...asset, id: assetId, owner: address } })) 
    //     }
    //   })
    // )
    // dispatch(updateTotals({ algo: nonZeroAssets.length }))

  } catch (err) {
    dispatch(fetchAssetError(err))
  }
}

const retry = <T>(fn: () => Promise<T>, interval=1000, retriesLeft=5): Promise<T> => new Promise((resolve, reject) => {
  fn()
    .then(resolve)
    .catch((error: any) => {
      setTimeout(() => {
        if(retriesLeft === 1) {
          return reject(error)
        }
        retry(fn, interval * 2, --retriesLeft).then(resolve, reject)
      }, interval)
    })
})

export const fetchAlgorandAsset = (
  library: AlgorandLibrary,
  assetId: string,
): AppThunk => async (dispatch): Promise<void> => {
  const assetInfo = await retry<IndexerAsset>(() => library.getAssetInformation(assetId), 1000, 5)

  const chainId = library.getCurrentChain()

  dispatch(addASATracker({
    chainId: chainId,
    tracker: {
      trackerId: assetId,
      name: assetInfo.params.name,
      interface: [],
      decimals: assetInfo.params.decimals,
      symbol: assetInfo.params['unit-name'],
      total: assetInfo.params.total,
      creator: assetInfo.params.creator,
      clawbackaddr: assetInfo.params.clawback,
      freezeaddr: assetInfo.params.freeze,
      managerkey: assetInfo.params.manager,
    }
  }))
  
  const creationInfo = await library.getCreationInformationForAsset(assetId)

  if (!isUndefined(creationInfo?.tokenUri)) {
    const tokenUri = creationInfo?.tokenUri as string
    const value = await fetch(tokenUri)
    const data = await value.json()
    dispatch(updateAsset({
      chainId,
      assetKey: assetId,
      asset: {
        tokenUri,
        data,
      }
    }))
  }
  if (!isUndefined(creationInfo?.isopair)) {
    dispatch(updateAsset({
      chainId,
      assetKey: assetId,
      asset: {
        approximate: true,
        isopair: creationInfo?.isopair as string,
      }
    }))
    if (!isUndefined(creationInfo?.originChainId)) {
      dispatch(updateAsset({
        chainId: Number(creationInfo?.originChainId) as ChainId,
        assetKey: creationInfo?.isopair as string,
        asset: {
          locked: true,
          isopair: assetId,
        }
      }))
    }
  }
}

export const fetchAlgorandAssets = (
  library: AlgorandLibrary,
  address: string,
): AppThunk => async (dispatch): Promise<void> => {
  try {
    console.log(`Fetching for ${address}`)
    
    const accnt: AccountInformation = await library.getAccountInformation(address)
    console.log(accnt)
    const nonZeroAssets = (accnt.assets ?? []).filter((aai) => aai.amount !== 0)

    const chainId = library.getCurrentChain()

    for (const aai of nonZeroAssets) {
      const assetId = aai['asset-id'].toString()
      dispatch(fetchAlgorandAsset(library, assetId))
      dispatch(addASA({
        chainId: chainId,
        asset: {
          creator: aai.creator,
          amount: aai.amount,
          frozen: aai['is-frozen'],
          id: assetId,
          owner: address,
        } 
      }))
    }
    dispatch(updateTotals({ algo: nonZeroAssets.length }))
  } catch (err) {
    dispatch(fetchAssetError({ err: err }))
  }
}
