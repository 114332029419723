import React from 'react'
import { useDispatch } from 'react-redux'
import { fetchAlgorandAssets, fetchEthereumAssets } from './action'
import { useActiveAlgorand } from '../../hooks/useActiveAlgorand'
import { useActiveEthereum } from '../../hooks/useActiveEthereum'
import { useAllEthereumNonfungiblesWithLoading, useAllEthereumTokensWithLoading } from '../../hooks/useAllTokens'

const AlgorandUpdater: React.FC = () => {
  const dispatch = useDispatch()
  const { library, account } = useActiveAlgorand()

  React.useEffect(() => {
    console.log(library, account)
    if (library && account) {
      dispatch(fetchAlgorandAssets(library, account))
    }
  }, [dispatch, library, account])

  return null
}

const EthereumUpdater: React.FC = () => {
  const dispatch = useDispatch()
  const { library, account, chainId } = useActiveEthereum()

  useAllEthereumTokensWithLoading()
  useAllEthereumNonfungiblesWithLoading()

  React.useEffect(() => {
    console.log(library, account, chainId)
    if (library && account && chainId) {
      dispatch(fetchEthereumAssets(library, account, chainId))
    }
  }, [dispatch, library, account, chainId])

  return null
}


export const Updater: React.FC = () => (
  <>
    <AlgorandUpdater/>
    <EthereumUpdater/>
  </>
)

export default Updater
