import { Contract } from '@ethersproject/contracts'
import { useActiveEthereum } from '../useActiveEthereum'
import { useMemo } from 'react'
import { isEthereumAddress } from '@djrhails/watcher-ui'
import { ZERO_ADDRESS } from '../../constants'
import { Web3Provider, JsonRpcSigner } from '@ethersproject/providers'

export * from './useMulticallContract'
export * from './useMorphContract'


export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// TODO(DJRHails): Defined a valid ABI Type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isEthereumAddress(address) || address === ZERO_ADDRESS) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useContract(address?: string, ABI?: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveEthereum()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}