import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton
} from '@djrhails/watcher-ui'
import { Network } from '../../constants'
import { SelectMethod } from './SelectMethod'
import { toHumanName } from '../../constants/Network'
import { ActivateModalProps, Method } from './index'

export interface ActivateNetworkModalProps extends ActivateModalProps {
  network: Network;
  methods: {
    [method: string]: Method;
  };
}

export function ActivateModal({
  isOpen,
  onClose,
  methods,
  network, }: ActivateNetworkModalProps): React.ReactElement {
  const [method, setMethod] = React.useState<string | undefined>(undefined)

  const clear = () => {
    setMethod(undefined)
    onClose()
  }

  const props = {
    onClose: clear,
  }

  return (
    <Modal isOpen={isOpen} onClose={clear}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Connect to an {toHumanName(network)} Wallet</ModalHeader>
          <ModalCloseButton />
          {!method && (
            <SelectMethod
              network={network}
              methods={Object.keys(methods)}
              setMethod={setMethod} />)}
          {Object.entries(methods).filter(([k, _]) => k === method).map(([k, V]) => <V key={k} {...props} />)}
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
