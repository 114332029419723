import React, { useState, useEffect } from 'react'
import {
  Container,
  Flex,
  Grid,
  Heading,
  Nifty,
  Button,
  Icon,
  NiftyProps,
} from '@djrhails/watcher-ui'
import { normaliseTrackerId, Token, toNiftyProps, useTokens } from '../../state/assets/hooks'
import { Network } from '../../constants'
import { ChainId, chainToPlatform } from '../../constants/ChainId'
import { startTransfer } from '../../state/transfer/action'
import { useDispatch } from 'react-redux'
import { AssetInfo, TrackerData } from '../../state/assets/reducer'
import { parseAssetKey } from '../../state/assets/action'
import { invertPlatform, toVariantColor } from '../../constants/Network'

interface NiftySectionProps {
  nifties: NiftyProps[];
}

interface FlippableNiftyProps extends NiftyProps {
  onClick(): void;
  originPlatform: 'eth' | 'algo';
  locked?: boolean;
  pair?: string;
  approximate?: boolean;
}

const FlippableNifty: React.FC<FlippableNiftyProps> = ({
  onClick,
  locked,
  pair,
  originPlatform,
  approximate,
  ...props
}: FlippableNiftyProps) => {
  const flipped = false

  const press = () => {
    if (!locked) {
      onClick()
    }
  }

  const pairedID = parseAssetKey(pair ?? '')

  const colors = {
    startColor: toVariantColor(originPlatform),
    endColor: toVariantColor(invertPlatform(originPlatform)),
  }

  return (
    <Nifty
      {...props}
      flipped={flipped || undefined}
    >
      <Flex m={5} width={1} direction="column" justify="center">
        { pairedID.trackerId && <Flex my={3}>
          <Icon size={5} name="SwapHoriz" mr={4}/> Paired to {normaliseTrackerId(pairedID.trackerId)} {pairedID.innerId}
        </Flex> }
        <Button variant="gradient" {...colors} onClick={press}>
          <Flex>
            {locked && <Icon name="Lock"/>} 
            {approximate ? 'Return' : 'Transfer'}
          </Flex>
        </Button>
      </Flex>
    </Nifty>
  )
}

interface NiftyFromTokenProps {
  chainId: ChainId;
  tracker: TrackerData;
  asset: AssetInfo;
}

const NiftyFromToken: React.FC<NiftyFromTokenProps> = (token: NiftyFromTokenProps) => {
  const [nifty, setNifty] = useState<NiftyProps>()

  const dispatch = useDispatch()

  // This is a hack...
  const approximate = token.tracker.symbol?.startsWith('~')

  const onClick = (): void => {
    dispatch(startTransfer({
      originChainId: token.chainId,
      assetKey: token.asset.assetKey,
      isopair: token.asset.isopair
    }))
  }

  useEffect(() => {
    setNifty(toNiftyProps(token))
  }, [token])

  return nifty 
    ? <FlippableNifty
      onClick={onClick}
      pair={token.asset.isopair}
      locked={token.asset.locked}
      approximate={approximate}
      originPlatform={chainToPlatform(token.chainId)}
      {...nifty}
    />
    : null
}

export const NiftySection: React.FC<NiftySectionProps> = () => {

  // TODO(djrhails): Add loading back in
  const loading = false

  const algoTokens = useTokens(Network.Algorand)
  const ethTokens = useTokens(Network.Ethereum)
  const tokens = [...ethTokens, ...algoTokens]

  const displayTokens: Required<Token>[] = tokens
    .filter((token) => token.tracker?.decimals === 0)
    .filter((token) => token.tracker && token.asset)
    .filter((token) => !token.asset?.burned)
    .map((t) => t as Required<Token>)

  if (!loading && displayTokens.length === 0) {
    return null
  }

  return (
    <Container as="section">
      <Heading.h4 ml={2}>
        NFTs
      </Heading.h4>
      <Grid mt={4} px={3} width="full" spacing={8} columns={[1, 1, 2, 3, 3]}>
        {displayTokens?.map((token, idx) => {
          return <NiftyFromToken
            key={idx}
            {...token}
          />
        })}
      </Grid>
    </Container>
  )
}

