import React, { useState, useEffect, PropsWithChildren } from 'react'
import { useWeb3React } from '@web3-react/core'

import { network, algoexplorer } from '../../connectors'
import { Network } from '../../constants'
import { useEagerConnect } from '../../hooks/useEagerConnect'
import { useInactiveListener } from '../../hooks/useInactiveListener'

interface UseSetupReturn {
  triedEager: boolean;
  active: boolean;
  fallbackError?: Error;
  fallbackActive: boolean;
}

const useSetupAlgorand = (): UseSetupReturn => {
  const { active, activate } = useWeb3React(Network.Algorand)

  // TODO: Eager via saved encrypted key
  useEffect(() => {
    if (!active) {
      activate(algoexplorer)
    }
  }, [active, activate])

  return {
    triedEager: false,
    active,
    fallbackActive: false,
  }
}

const useSetupEthereum = (): UseSetupReturn => {
  const { active } = useWeb3React(Network.Ethereum)
  const { active: fallbackActive, error: fallbackError, activate: activateFallback } = useWeb3React(Network.EthereumFallback)

  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect(Network.Ethereum)

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !fallbackActive && !fallbackError && !active) {
      activateFallback(network)
    }
  }, [triedEager, fallbackActive, fallbackError, activateFallback, active])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(Network.Ethereum, !triedEager)

  return {
    triedEager,
    active,
    fallbackError,
    fallbackActive,
  }
}

export const Web3ReactManager: React.FC = ({ children }: PropsWithChildren<unknown>) => {
  const eth = useSetupEthereum()
  const algo = useSetupAlgorand()

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true)
    }, 600)

    return (): void => {
      clearTimeout(timeout)
    }
  }, [])

  // on page load, do nothing until we've tried to connect to the injected connector
  if (!eth.triedEager) {
    return null
  }

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!eth.active && eth.fallbackError) {
    return (
      <h1>
        Unrecoverable Error
      </h1>
    ) // TODO(DJRHails): Style nicely
  }

  // if neither context is active, spin
  if ((!eth.active && !eth.fallbackActive) || !algo.active) {
    return showLoader ? (
      <h1>
        Loading
      </h1> // TODO(DJRHails): Loading
    ) : null
  }

  return (
    <>
      {children}
    </>
  )
}
