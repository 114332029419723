import { useEffect, useState } from 'react'
import { isEthereumAddress } from '@djrhails/watcher-ui'
import { useActiveEthereum } from './useActiveEthereum'
import { makeCancellable } from './makeCancellable'

export function useENSName(address?: string): string | null {
  const { library } = useActiveEthereum()
  const [ENSName, setENSName] = useState<string | null>(null)

  useEffect(() => {
    if (!library || !address) return

    const validated = isEthereumAddress(address)
    if (validated) {
      const { promise, cancel } = makeCancellable(library.lookupAddress(validated))
      promise
        .then(name => (name)
          ? setENSName(name)
          : setENSName(null)
        )
        .catch(() => setENSName(null))
      
      return cancel
    }
  }, [library, address])

  return ENSName
}