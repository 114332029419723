import { useMemo } from 'react'
import { useMultipleContractWithMultipleData, ContractMap, CallInputs } from '../state/multicall/hooks'
import { EIP721_ENUM_INTERFACE, EIP721_META_INTERFACE } from '../constants/abis/eip721'
import { BigNumber } from '@ethersproject/bignumber'
import _ from 'lodash'


type TokenUris = ContractMap<{id: BigNumber; uri: string}[]>
type TokenUrisWithLoading = {
  tokenUris: TokenUris;
  loading: boolean;
}
type OwnedTokens = ContractMap<BigNumber[]>
type OwnedTokensWithLoading = [OwnedTokens, boolean]

export function useTokenUrisWithLoading(owned: OwnedTokens): TokenUrisWithLoading {
  const callInputs = useMemo(
    () => Object.keys(owned).reduce<CallInputs>(
      (memo, addr) => {
        memo[addr] = owned[addr].map((id) => [id])
        return memo
      },
      {})
    , [owned])


  const tokenCallsByAddress = useMultipleContractWithMultipleData(
    Object.keys(owned),
    EIP721_META_INTERFACE,
    'tokenURI',
    callInputs
  )

  const loading = Object.values(tokenCallsByAddress).some(callState => callState.some(cs => cs.loading))

  const tokenUrisByAddress = useMemo(
    () => Object.keys(tokenCallsByAddress).reduce<TokenUris>(
      (memo, addr) => {
        const values = tokenCallsByAddress[addr].map(call => call.result?.[0])
        if (values && values.every(v => v)) {
          memo[addr] = values.map((v, idx) => ({
            id: owned[addr][idx],
            uri: v,
          }))
        }
        return memo
      }, {}),
    [tokenCallsByAddress, owned]
  )
  return { tokenUris: tokenUrisByAddress, loading }
}

export function useTokenEnumerationWithLoading(
  balanceSet: ContractMap<BigNumber>,
  account?: string,
): OwnedTokensWithLoading {
  const tokenAddresses = useMemo(() => Object.keys(balanceSet), [balanceSet])

  const callInputs = useMemo(
    () => Object.keys(balanceSet).reduce<CallInputs>(
      (memo, addr) => {
        const balance = balanceSet[addr]
        memo[addr] = _.range(balance.toNumber()).map((id) => [account, id])
        return memo
      },
      {})
    , [account, balanceSet])
    
  const tokenIdsPerContract = useMultipleContractWithMultipleData(tokenAddresses, EIP721_ENUM_INTERFACE, 'tokenOfOwnerByIndex', callInputs)

  const anyLoading = Object.values(tokenIdsPerContract).some(callState => callState.some(cs => cs.loading))

  return [
    useMemo(
      () => tokenAddresses.reduce<OwnedTokens>(
        (memo, addr) => {
          const values = tokenIdsPerContract[addr].map(call => call.result?.[0])
          if (values && values.every(v => v)) {
            memo[addr] = values
          }
          return memo
        }, {})
      ,
      [tokenIdsPerContract, tokenAddresses]
    ),
    anyLoading
  ]
}