import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from '@djrhails/watcher-ui'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../state'
import { abortTransfer } from '../../state/transfer/action'
import { invertNetwork, toHumanName } from '../../constants/Network'
import { ChainId, chainToNetwork } from '../../constants/ChainId'
import { TransferInfo, TransferStage } from '../../state/transfer/state'
import Step from './Step'
import styled from 'styled-components'
import { AlgorandLibrary } from '../../providers/AlgorandWeb3Provider'
import { Web3Provider as EthereumLibrary } from '@ethersproject/providers'
import { useNetwork } from '../../hooks/useNetwork'

export interface Libraries {
  originLibrary: AlgorandLibrary | EthereumLibrary;
  destinationLibrary: AlgorandLibrary | EthereumLibrary;
}

export interface TransferProps {
  info: TransferInfo;
  libs: Libraries;
}

const ModalContentOverride = styled<any>(ModalContent)({
  maxWidth: '45rem',
})

export const TransferModal: React.FC = () => {
  const active = useSelector((state: AppState) => state.transfer.active)
  const transfer = useSelector((state: AppState) => state.transfer.transfers?.[active?.chainId ?? 0]?.[active?.assetKey ?? ''])

  const network = chainToNetwork(active?.chainId ?? ChainId.ETH_MAINNET)
  const destinationNetwork = invertNetwork(network)

  const { library: originLibrary } = useNetwork(network)
  const { library: destinationLibrary } = useNetwork(destinationNetwork)

  const dispatch = useDispatch()
  const abort = () => {
    console.log('disabling')
    dispatch(abortTransfer())
  }

  const { isOpen, onClose } = useDisclosure({
    isOpen: active !== undefined,
    onClose: abort,
  })

  if (originLibrary === undefined || destinationLibrary === undefined) {
    return <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>No connection to the blockchain</ModalHeader>
          <ModalCloseButton/>
          Please try again later, check your connections to Algorand / Ethereum. 
        </ModalContent>
      </ModalOverlay>
    </Modal>
  }

  const libs = {
    originLibrary,
    destinationLibrary,
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContentOverride>
          <ModalHeader>Transfer Asset to {toHumanName(destinationNetwork)}</ModalHeader>
          <ModalCloseButton />

          {transfer?.stage === TransferStage.START && <Step.Summarise info={transfer} libs={libs}/>}
          {transfer?.stage === TransferStage.FINDING_COMMITTEE && <Step.Committee info={transfer} libs={libs}/>}
          
          {transfer?.stage === TransferStage.DEPLOY_PAIR_ASSET && <Step.Pair info={transfer} libs={libs}/>}
          {transfer?.stage === TransferStage.AWAITING_LOCKUP && <Step.Lock info={transfer} libs={libs}/>}
          {transfer?.stage === TransferStage.VERIFY_LOCKUP && <Step.Verify info={transfer} libs={libs}/>}

          {transfer?.stage === TransferStage.AWAITING_BURN && <Step.Burn info={transfer} libs={libs}/>}
          {transfer?.stage === TransferStage.VERIFY_BURN && <Step.Verify info={transfer} libs={libs}/>}

          {transfer?.stage === TransferStage.ERROR && <Step.Error onClose={onClose} info={transfer} libs={libs}/>}
        </ModalContentOverride>
      </ModalOverlay>
    </Modal>
  )
}