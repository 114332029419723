/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChainId } from '../constants'
import sdk, {
  AccountInformation,
  Algodv2,
  TxResult,
  Indexer,
  IndexerAsset,
  decodeObj,
  SuggestedParams,
  ConfirmedTxInfo,
} from 'algosdk'
import invariant from 'tiny-invariant'
import { NetworkV2Connector } from '../connectors/NetworkV2Connector'


interface CreateAssetProps {
  assetName: string;
  unitName: string;
  assetURL: string;
  
  managerAddress?: string;
  reserveAddress?: string;
  freezeAddress?: string;
  clawbackAddress?: string;

  supply?: number;
  decimals?: number;
  metadata?: Record<string, string>; 
}

export class AlgorandLibrary {
  private provider: {
    algod: Algodv2,
    indexer: Indexer,
  };
  private connector: NetworkV2Connector;

  // TODO(djrhails): Should be AbstractConnector / getCurrentChain be async.
  constructor(provider: any, connector: NetworkV2Connector) {
    invariant(provider, 'Providers for Algorand must be Algod')
    this.provider = provider
    this.connector = connector
  }

  getCurrentChain(): ChainId {
    return this.connector.currentChainId
  }

  getClient(): Algodv2 {
    return this.provider.algod
  }

  getIndexer(): Indexer {
    return this.provider.indexer
  }

  async getActiveAccount(): Promise<string | undefined> {
    return (await this.connector.getAccount()) ?? undefined
  }

  async getAccountInformation(address: string): Promise<AccountInformation> {
    console.log(`Getting accountInformation ${address} on ${this.getCurrentChain()}`)
    return await this.getClient().accountInformation(address).do()
  }

  async getAssetInformation(assetID: any): Promise<IndexerAsset> {
    console.log(`Getting assetID ${assetID}`)
    const assetInfo = await this.getIndexer().lookupAssetByID(assetID).do()

    return assetInfo.asset
  }

  async getCreationInformationForAsset(assetID: any): Promise<Record<string, unknown> | undefined> {
    const acfgs = await this.getIndexer().searchForTransactions()
      .txType('acfg')
      .assetID(assetID)
      .do()
    try {
      return decodeObj(Buffer.from(acfgs.transactions[0]?.note, 'base64'))
    } catch (err) {
      return {
        raw: acfgs.transactions[0]?.note,
        error: err,
      }
    }
  }

  async getSuggestedParams(): Promise<SuggestedParams> {
    const params = await this.getClient().getTransactionParams().do()

    console.log('params: ', params)

    return {
      fee: params.fee,
      flatFee: params.flatFee,
      firstRound: params.firstRound,
      lastRound: params.lastRound,
      genesisID: params.genesisID,
      genesisHash: params.genesisHash
    }
  }

  // TODO(djrhails): Cleanup
  async waitForConfirmation(txId: any): Promise<ConfirmedTxInfo> {
    const status = (await this.getClient().status().do())
    let lastRound = status['last-round']
    let pendingInfo
    // eslint-disable-next-line no-constant-condition
    while (true) {
      pendingInfo = await this.getClient().pendingTransactionInformation(txId).do()
      const confirmedRound = pendingInfo['confirmed-round']
      if (confirmedRound && confirmedRound > 0) {
        //Got the completed Transaction
        console.log('Transaction ' + txId + ' confirmed in round ' + confirmedRound)
        break
      }
      console.log('Checking round:', lastRound)
      lastRound++
      await this.getClient().statusAfterBlock(lastRound).do()
    }

    return pendingInfo
  }

  async createAsset(props: CreateAssetProps): Promise<TxResult>{
    const {
      supply,
      unitName,
      assetName,
      assetURL,
      managerAddress,
      reserveAddress,
      freezeAddress,
      clawbackAddress,
      decimals,
      metadata
    } = props

    console.log('Creating asset with: ', props)
    const suggestedParams = await this.getSuggestedParams()
    const note = sdk.encodeObj(metadata || {})
    const addr = await this.getActiveAccount()
    const sk = this.connector.sk

    if (addr === undefined || sk === undefined) {
      return Promise.reject('No keys given')
    } 

    const txnParams = {
      'from': addr,
      'note': note,
      'suggestedParams': suggestedParams,
      'assetTotal': supply ?? 1,
      'assetDecimals': decimals ?? 0,
      'assetDefaultFrozen': false,
      'assetUnitName': unitName,
      'assetName': assetName,
      'assetURL': assetURL,
      'assetManager': managerAddress ?? addr,
      'assetReserve': reserveAddress ?? addr,
      'assetFreeze': freezeAddress ?? addr,
      'assetClawback': clawbackAddress ?? addr,
      'type': 'acfg' as const,
    }

    const txn = new sdk.Transaction(txnParams)
    const signedTxn = txn.signTxn(sk)
    const txId = txn.txID().toString()
    console.log('Signed transaction with txID: %s', txId)

    const transaction = await this.getClient().sendRawTransaction(signedTxn).do()

    console.log('Sent transaction', transaction)

    return transaction
  }

  async pendingTransactionInformation(txId: any): Promise<ConfirmedTxInfo> {
    return this.getClient().pendingTransactionInformation(txId).do()
  }

  async destroyAsset(assetId: number): Promise<TxResult> {
    const cp = await this.getSuggestedParams()
    const addr = await this.connector.getAccount()
    const sk = this.connector.sk

    if (addr === null || sk === undefined) {
      return Promise.reject('No keys given')
    } 

    const note = new Uint8Array(Buffer.from('isoburn', 'base64'))

    const txn = sdk.makeAssetDestroyTxn(
      addr,
      cp.fee,
      cp.firstRound,
      cp.lastRound,
      note,
      cp.genesisHash,
      cp.genesisID,
      assetId
    )
    const rawSignedTxn = txn.signTxn(sk)

    const txRes = await this.getClient().sendRawTransaction(rawSignedTxn).do()
    return txRes
  }

  isValidAddress(addr: any): boolean {
    return sdk.isValidAddress(addr)
  }
}