import React from 'react'
import { TokenDisplay } from '../../pages/Dashboard/TokenSection'
import { FlippedAssetRow } from './FlippedAssetRow'
import { useDispatch } from 'react-redux'
import { startTransfer } from '../../state/transfer/action'
import { BigNumber } from '@ethersproject/bignumber'

export const AssetRow: React.FC<TokenDisplay> = ({
  token,
  platform,
}: TokenDisplay) => {
  const flipped = false
  const dispatch = useDispatch()
  const approximate = token.tracker?.symbol?.startsWith('~')
  
  const onClick = (): void => {
    if (!token.tracker) {
      return
    }
    dispatch(startTransfer({
      originChainId: token.chainId,
      assetKey: token.tracker.trackerId,
      amount: BigNumber.from(token.tracker?.decimals),
      isopair: token.asset?.isopair,
    }))
  }

  return (
    <FlippedAssetRow
      token={token}
      platform={platform}
      flipped={flipped}
      locked={token.asset?.locked}
      approximate={approximate}
      onClick={onClick}
    />
  )
}
