import React from 'react'
import { Network, networkToColor } from '../../constants/Network'

export const Gradient: React.FC = () => {
  return (
    <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
      <stop offset="0%" style={{
        stopColor: networkToColor(Network.Ethereum),
        stopOpacity: 1,
      }} />
      <stop offset="100%" style={{
        stopColor: networkToColor(Network.Algorand),
        stopOpacity: 1,
      }} />
    </linearGradient>
  )
}
