import { createReducer } from '@reduxjs/toolkit'
import { addASA, addASATracker, addEthToken, toAssetKey, updateAsset, updateTotals, updateTracker } from './action'
import { EIP721Metadata } from '../../constants/tokens/EIP721'
import { ALL_TRACKERS } from '../../constants/tokens'
import { BigNumberish } from '@ethersproject/bignumber'

export interface AssetInfo {
  assetKey: string;
  owner: string;

  // Fungible, =1 in Nonfungibles
  balance: BigNumberish; // In trackers base unit, not decimalised
  
  // Nonfungible only
  tokenUri?: string;
  data?: EIP721Metadata;

  // Error
  error?: any;

  // Pair Status
  isopair?: string;
  approximate?: boolean; // i.e. is this a ~Asset

  // Asset Status
  locked?: boolean;
  burned?: boolean;
}

export enum TrackerInterface {
  EIP20,
  EIP721,
}

/**
 * Only relevant for non-hardcoded information about an asset
 */
export interface TrackerData {
  trackerId: string;
  name: string; // aka assetname in ASA
  interface: TrackerInterface[];
  decimals?: number;
  symbol?: string; // aka unitname in ASA
  total?: number;
  
  creator?: string; // Creator of asset in ASA, Smart contract in ETH

  clawbackaddr?: string;
  defaultfrozen?: boolean;
  freezeaddr?: string;
  managerkey?: string;
  reserveaddr?: string;

  // Market data
  price?: number;
}

/**
 * TrackerId denotes either an Ethereum address, or an ASA ID
 */
export interface AssetState {
  trackers: {
    [chainId: number]: {
      [trackerId: string]: TrackerData;
    };
  };
  assets: {
    [chainId: number]: {
      [assetKey: string]: AssetInfo;
    };
    loading: boolean;
    error?: any;
  };
  totals: {
    eth: number;
    algo: number;
  };
}

const INITIAL_STATE: AssetState = {
  trackers: {
    ...ALL_TRACKERS,
  },
  assets: {
    loading: true,
  },
  totals: {
    eth: 0,
    algo: 0,
  }
}

export default createReducer(INITIAL_STATE, builder =>
  builder
    .addCase(addASA, (state, {
      payload: {
        chainId,
        asset
      }
    }) => {
      const assets: AssetState['assets'] = state.assets
      assets[chainId] = assets[chainId] ?? {}

      const assetKey = toAssetKey({trackerId: asset.id})

      assets[chainId][assetKey] = {
        assetKey: assetKey,
        owner: asset.owner,
        balance: asset.amount,
      }
    })
    .addCase(addASATracker, (state, {
      payload: {
        chainId,
        tracker,
      }
    }) => {
      const trackerId = tracker.trackerId
      const trackers: AssetState['trackers'] = state.trackers
        ? state.trackers
        : (state.trackers = {})
      trackers[chainId] = trackers[chainId] ?? {}
      trackers[chainId][trackerId] = {
        ...trackers[chainId][trackerId],
        ...tracker,
      }
    })
    .addCase(addEthToken, (state, {
      payload: {
        chainId,
        trackerId,
        asset: {
          innerId,
          owner,
          balance,
          tokenUri,
          data,
        },
      }
    }) => {
      const assets: AssetState['assets'] = state.assets
      assets[chainId] = assets[chainId] ?? {}

      const assetKey = toAssetKey({ trackerId: trackerId, innerId: innerId })

      assets[chainId][assetKey] = {
        ...assets[chainId][assetKey],
        assetKey: assetKey,
        owner: owner,
        balance: balance,
        tokenUri: tokenUri,
        data: {
          ...assets[chainId][assetKey]?.data,
          ...data,
        },
      }
    })
    .addCase(updateAsset, (state, {
      payload: {
        chainId,
        assetKey,
        asset
      }
    }) => {
      const assets: AssetState['assets'] = state.assets
      assets[chainId] = assets[chainId] ?? {}
      const previousAsset = assets[chainId][assetKey]

      assets[chainId][assetKey] = {
        ...previousAsset,
        ...asset,
        data: {
          ...previousAsset?.data,
          ...asset?.data,
        }
      }
    })
    .addCase(updateTracker, (state, {
      payload: {
        chainId,
        trackerId,
        tracker
      }
    }) => {
      const trackers: AssetState['trackers'] = state.trackers
      trackers[chainId] = trackers[chainId] ?? {}
      const previousTracker = trackers[chainId][trackerId]

      trackers[chainId][trackerId] = {
        ...previousTracker,
        ...tracker,
      }
    })
    .addCase(updateTotals, (state, {
      payload: {
        algo,
        eth
      }
    }) => {
      state.totals.algo = algo ?? 0
      state.totals.eth = eth ?? 0
    })
)