import { Network, ChainId } from '../../constants'
import { useSelector } from 'react-redux'
import { AppState } from '../index'
import { AssetInfo, TrackerData } from './reducer'
import { parseAssetKey } from './action'
import { useMemo } from 'react'
import { useNetwork } from '../../hooks/useNetwork'
import { chainToPlatform, CHAIN_EXPLORERS } from '../../constants/ChainId'
import { NiftyProps, shortenAddress } from '@djrhails/watcher-ui'
import { BigNumber, formatFixed } from '@ethersproject/bignumber'

export interface Token {
  chainId: ChainId;
  asset?: AssetInfo;
  tracker?: TrackerData;
}

export const useTokens = (networkId: Network): Token[] =>{
  const { chainId } = useNetwork(networkId)
  const assets = useSelector((state: AppState) => state.assets.assets[chainId ?? -1])
  const trackers = useSelector((state: AppState) => state.assets.trackers[chainId ?? -1])

  return useMemo((): Token[] => {
    if (!assets) {
      return []
    }
    const tokens = Object.keys(assets)
      .map((assetKey): Token | undefined => {
        const assetId = parseAssetKey(assetKey)
        if (!assets[assetKey] || !trackers[assetId.trackerId]) {
          return undefined
        }
        return {
          chainId: chainId ?? -1,
          asset: assets[assetKey],
          tracker: trackers[assetId.trackerId]
        }
      }
      ).filter((token): token is Token => token !== undefined)
    return tokens ?? []
  }
  , [assets, trackers, chainId]) ?? []
}

export const useToken = (chainId: ChainId, assetKey: string): Token => {
  const assetId = parseAssetKey(assetKey)
  const asset = useSelector((state: AppState) => state.assets.assets[chainId ?? -1]?.[assetKey])
  const tracker = useSelector((state: AppState) => state.assets.trackers[chainId ?? -1]?.[assetId.trackerId])

  return {
    chainId,
    asset,
    tracker
  }
}

export const useWorth = (networkId: Network): number => {
  const tks = useTokens(networkId)
  const worth = useMemo(() => {
    return tks
      .map((token) => (token.tracker?.price ?? 0) * Number(formatFixed(BigNumber.from(token.asset?.balance), token.tracker?.decimals)) ?? 0)
      .reduce((acc, n) => acc + n, 0)
  }, [tks])
  return worth 
}

export const normaliseTrackerId = (trackerId: string): string => {
  return trackerId.startsWith('0x') ? shortenAddress(trackerId) : `#${trackerId}`
}

export const toNiftyProps = (token: Token): NiftyProps => {
  const assetId = parseAssetKey(token.asset?.assetKey ?? '')
  const explorer = CHAIN_EXPLORERS[token.chainId]
  
  return {
    id: parseInt(assetId.innerId ?? '0x0'),
    platform: chainToPlatform(token.chainId),
    trackerId: normaliseTrackerId(assetId.trackerId),
    trackerExplorer: explorer && `${explorer}/${assetId.trackerId}`,
    ratio: 1/1,
    data: { 
      assetName: token.tracker?.name ?? '',
      assetId: token.tracker?.trackerId ?? 0,
      owner: token.asset?.owner ?? '',
      name: token.asset?.data?.name,
      src: token.asset?.tokenUri ?? 'error',
      img: token.asset?.data?.image ?? token.asset?.data?.imageUrl ?? `https://via.placeholder.com/340x255.png?text=${token.tracker?.symbol ?? 'Loading'}`
    }
  }
}

export const useNifty = (chainId: ChainId, assetKey: string): {props: NiftyProps, loading: boolean} => {
  const token = useToken(chainId, assetKey)

  return {
    props: toNiftyProps(token),
    loading: token.tracker?.symbol === undefined,
  }
}