import { Interface } from '@ethersproject/abi'
import EIP20_ABI from './eip20.json'

const EIP20_INTERFACE = new Interface(EIP20_ABI)

export default EIP20_INTERFACE
export { EIP20_INTERFACE, EIP20_ABI }

// supportedInterfaces[0x36372b07] = true; // ERC20
// supportedInterfaces[0x06fdde03] = true; // ERC20 name
// supportedInterfaces[0x95d89b41] = true; // ERC20 symbol
// supportedInterfaces[0x313ce567] = true; // ERC20 decimals