import React from 'react'
import {
  Identicon,
  Text,
  Flex,
  Button,
  shortenAddress,
  Icon,
  useClipboard,
  FlexProps
} from '@djrhails/watcher-ui'
import { useENSName } from '../../hooks/useENSName'
import { UnsupportedChainIdError } from '@web3-react/core'
import { Network, toPlatform, toHumanName } from '../../constants/Network'

interface AccountBlockProps extends FlexProps {
  network: Network;
  copyable?: true;
  account?: string;
  error?: any;
}

export const AccountBlock: React.FC<AccountBlockProps> = ({
  account,
  copyable,
  error,
  network,
  ...props
}: AccountBlockProps) => {
  const { onCopy } = useClipboard(account ?? '')
  const ENSName = useENSName(account ?? undefined)

  const copyAddress = () => {
    if (copyable && account) {
      console.log('Copying: ', account)
      onCopy()
    }
  }

  const StatusIcon: React.FC = () => {
    if (account) {
      return <Identicon
        address={account}
        diameter={24}
        allowOverlap
        platform={toPlatform(network)} />
    }
    return null
  }

  if (account) {
    return (
      <Flex width={1} justify="space-between" {...props}>
        <Flex>
          <StatusIcon />
          <Text ml={3}>{ENSName || shortenAddress(account)}</Text>
        </Flex>
        { copyable && (
          <Button
            buttonColor="white"
            textColor="gray"
            p={2}
            borderRadius="full"
            ml={3}
            onClick={copyAddress}
          >
            <Icon name="ContentCopy"/>
          </Button>
        )}
      </Flex>
    )
  }
  else if (error) {
    return (
      <Flex>
        <Icon name="Autorenew" />
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Chain' : 'Error'}</Text>
      </Flex>
    )
  }
  else {
    return (
      <Text>Connect to {toHumanName(network)}</Text>
    )
  }
}
