import { useWeb3React } from '@web3-react/core'
import { Network, ChainId } from '../constants'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { Web3Provider as EthereumLibrary } from '@ethersproject/providers'

export function useActiveEthereum(): Web3ReactContextInterface<EthereumLibrary> & { chainId?: ChainId } {
  const context = useWeb3React<EthereumLibrary>(Network.Ethereum)
  const contextFallback = useWeb3React<EthereumLibrary>(Network.EthereumFallback)
  return context.active ? context : contextFallback
}
