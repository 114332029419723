import React, { useCallback, useState, FormEvent } from 'react'
import {
  Flex,
  Text,
  ModalBody,
  ModalFooter,
  Button,
  Link,
  Box,
} from '@djrhails/watcher-ui'
import { Network } from '../../constants'
import { useWeb3React } from '@web3-react/core'
import { algoexplorer } from '../../connectors'
import { NetworkV2Connector } from '../../connectors/NetworkV2Connector'
import algoSdk from 'algosdk'
import { ActivateModal } from './ActivateModal'
import { MethodProps, ActivateModalProps } from '.'

function MnemonicActivation({ onClose }: MethodProps): React.ReactElement {
  const { connector } = useWeb3React(Network.Algorand)

  const [mnemonic, setMnemonic] = useState<string>('table lend syrup action pelican nut correct vendor vessel reduce weasel picnic trust rubber festival figure useless ill neglect fatigue clog trigger broom abandon ceiling')

  const onConnect = useCallback(() => {
    const keys = algoSdk.mnemonicToSecretKey(mnemonic)
    if (connector === algoexplorer) {
      const purestakeConnector = connector as NetworkV2Connector
      purestakeConnector.changeKeys(keys)
    }
    onClose()
  }, [connector, mnemonic, onClose])

  return (
    <>
      <ModalBody>
        <Text fontWeight="medium" pb={2}>Mnemonic</Text>
        <Box
          as="textarea"
          value={mnemonic}
          onChange={(event: FormEvent<HTMLTextAreaElement>): void => setMnemonic(event.currentTarget.value)}
          sx={{
            width: '100%',
            height: '9rem',
            borderColor: 'muted',
            borderStyle: 'solid',
            borderWidth: 'thin',
            padding: '1rem',
            borderRadius: 'default'
          }} />
        <Flex justify="flex-end">
          <Link color="accent" href="https://wallet.myalgo.com/" target="_blank">
        Don&apos;t have an account? Create here
          </Link>
        </Flex>
      </ModalBody>

      <ModalFooter justify="center">
        <Button buttonColor="accent" onClick={onConnect}>Connect</Button>
      </ModalFooter>
    </>
  )
}

export function AlgorandActivateModal({ isOpen, onClose }: ActivateModalProps): React.ReactElement {
  const algorandMethods = {
    'Mnemonic': MnemonicActivation,
  }

  return <ActivateModal
    isOpen={isOpen}
    onClose={onClose}
    network={Network.Algorand}
    methods={algorandMethods}
  />
}
