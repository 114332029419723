import { Network, ChainId } from '../constants'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { Web3Provider as EthereumWeb3Provider } from '@ethersproject/providers'
import { AlgorandLibrary } from '../providers/AlgorandWeb3Provider'
import { useActiveEthereum } from './useActiveEthereum'
import { useActiveAlgorand } from './useActiveAlgorand'

export function useNetwork(networkId: Network): Web3ReactContextInterface<EthereumWeb3Provider | AlgorandLibrary> & { chainId?: ChainId } {
  const ethContext = useActiveEthereum()
  const algoContext = useActiveAlgorand()
  return networkId === Network.Algorand ? algoContext : ethContext
}
