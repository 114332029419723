import { Network, toPlatform } from './Network'

export type ChainDict<V> = { [chainId in ChainId]: V }

export enum ChainId {
  ETH_MAINNET = 1,
  ETH_ROPSTEN = 3,
  ETH_RINKEBY = 4,
  ETH_GÖRLI = 5,
  ETH_KOVAN = 42,

  // ALGO_XXXX = u16(base32_decode(XXXX))
  ALGO_MAIN = 24592,
  ALGO_TEST = 39205,
  ALGO_BETA = 2342
}

export const createDict = <V>(defaultValue: V): ChainDict<V> => ({
  [ChainId.ETH_MAINNET]: defaultValue,
  [ChainId.ETH_ROPSTEN]: defaultValue,
  [ChainId.ETH_RINKEBY]: defaultValue,
  [ChainId.ETH_GÖRLI]: defaultValue,
  [ChainId.ETH_KOVAN]: defaultValue,

  [ChainId.ALGO_MAIN]: defaultValue,
  [ChainId.ALGO_TEST]: defaultValue,
  [ChainId.ALGO_BETA]: defaultValue
})

export const CHAIN_LABELS: ChainDict<string | undefined> = {
  [ChainId.ETH_MAINNET]: 'MAIN',
  [ChainId.ETH_RINKEBY]: 'RINKEBY',
  [ChainId.ETH_ROPSTEN]: 'ROPSTEN',
  [ChainId.ETH_GÖRLI]: 'GÖRLI',
  [ChainId.ETH_KOVAN]: 'KOVAN',
  [ChainId.ALGO_MAIN]: 'MAIN',
  [ChainId.ALGO_TEST]: 'TEST',
  [ChainId.ALGO_BETA]: 'BETA'
}

export const chainToNetwork = (chain: ChainId): Network => {
  return {
    [ChainId.ETH_MAINNET]: Network.Ethereum,
    [ChainId.ETH_RINKEBY]: Network.Ethereum,
    [ChainId.ETH_ROPSTEN]: Network.Ethereum,
    [ChainId.ETH_GÖRLI]: Network.Ethereum,
    [ChainId.ETH_KOVAN]: Network.Ethereum,
    [ChainId.ALGO_MAIN]: Network.Algorand,
    [ChainId.ALGO_TEST]: Network.Algorand,
    [ChainId.ALGO_BETA]: Network.Algorand
  }[chain]
}

export const chainToPlatform = (chain: ChainId): 'eth' | 'algo' => {
  return toPlatform(chainToNetwork(chain))
}

export const validPairs = (chain: ChainId): ChainId[] => {
  const algoTestChains = [ChainId.ALGO_TEST, ChainId.ALGO_BETA]
  const ethTestChains = [ChainId.ETH_RINKEBY, ChainId.ETH_ROPSTEN, ChainId.ETH_GÖRLI, ChainId.ETH_KOVAN]
  return {
    [ChainId.ETH_MAINNET]: [ChainId.ALGO_MAIN],
    [ChainId.ETH_RINKEBY]: algoTestChains,
    [ChainId.ETH_ROPSTEN]: algoTestChains,
    [ChainId.ETH_GÖRLI]: algoTestChains,
    [ChainId.ETH_KOVAN]: algoTestChains,
    [ChainId.ALGO_MAIN]: [ChainId.ETH_MAINNET],
    [ChainId.ALGO_TEST]: ethTestChains,
    [ChainId.ALGO_BETA]: ethTestChains,
  }[chain]
}

export const CHAIN_EXPLORERS: ChainDict<string | undefined> = {
  [ChainId.ETH_MAINNET]: 'https://etherscan.io/token',
  [ChainId.ETH_RINKEBY]: 'https://rinkeby.etherscan.io/token',
  [ChainId.ETH_ROPSTEN]: 'https://ropsten.etherscan.io/token',
  [ChainId.ETH_GÖRLI]: 'https://goerli.etherscan.io/token',
  [ChainId.ETH_KOVAN]: 'https://kovan.etherscan.io/token',
  [ChainId.ALGO_MAIN]: 'https://goalseeker.purestake.io/algorand/mainnet/asset',
  [ChainId.ALGO_TEST]: 'https://goalseeker.purestake.io/algorand/testnet/asset',
  [ChainId.ALGO_BETA]: 'https://goalseeker.purestake.io/algorand/betanet/asset'
}

export const CHAIN_SERVERS: ChainDict<string | undefined> = {
  ...createDict<string | undefined>(undefined),
  [ChainId.ALGO_MAIN]: 'https://mainnet-algorand.api.purestake.io/ps1',
  [ChainId.ALGO_TEST]: 'https://testnet-algorand.api.purestake.io/ps1',
  [ChainId.ALGO_BETA]: 'https://betanet-algorand.api.purestake.io/ps1'
}

