import { BigNumberish } from '@ethersproject/bignumber'
import { ChainId } from '../../constants/ChainId'

export enum TransferStage {
  START,
  FINDING_COMMITTEE,

  DEPLOY_PAIR_ASSET,
  AWAITING_LOCKUP,
  VERIFY_LOCKUP,
  PAIR_SIGNED,

  AWAITING_BURN,
  VERIFY_BURN,
  RELEASED,
  
  ERROR
}

export interface CommitteeMember {
  did: string;
  approved: boolean;
}

export interface TransferInfo {
  assetKey: string;
  stage: TransferStage;
  originChainId: ChainId;
  amount?: BigNumberish;
  destinationChainId?: ChainId;
  signingCommittee?: CommitteeMember[];
  pairedAssetKey?: string;
  returning?: boolean;
  error?: string;
}

export interface TransferState {
  transfers: {
    [originChainId: number]: {
      [assetKey: string]: TransferInfo;
    };
  };
  active?: {
    chainId: number;
    assetKey: string;
  };
}
