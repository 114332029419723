export const ETHEREUM_NONFUNGIBLES = [
  {
    id: 16,
    data: {
      src: 'internal://',
      assetName: 'Artifact',
      assetId: 16,
      owner: '0x67C3fD74fd36456FC5b339Fd09Efa330ad3126eE',
    
      // Optional Asset metadata
      assetTicker: 'ART',
    
      // Optional NiftyMetadata
      name: 'Gorilla',
      img: 'https://ipfs.io/ipfs/QmYe9Ngo3UBss48ud5Ny7byde3yXE1GeoWGuHH4oeekza6',
      bg: 'surface'
    },
    platform: 'eth'
  },
  {
    id: 1946112,
    data: {
      src: 'internal://',
      assetName: 'CryptoKitties',
      assetId: 16,
      owner: '0x67C3fD74fd36456FC5b339Fd09Efa330ad3126eE',
      name: 'Briciola Lackawow',
      img: 'https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/1946112.svg',
      bg: '#e6eafd'
    },
    platform: 'algo',
  },
  {
    id: 103,
    data: {
      src: 'internal://',
      assetName: 'Some NFT',
      assetId: 1,
      owner: '0x67C3fD74fd36456FC5b339Fd09Efa330ad3126eE',
      name: 'Imageless',
      bg: '#f0f5f9'
    },
    platform: 'algo',
  },
  {
    id: 532546,
    data: {
      src: 'internal://',
      assetName: 'CryptoKitties',
      assetId: 16,
      owner: '0x67C3fD74fd36456FC5b339Fd09Efa330ad3126eE',
      name: '400',
      img: 'https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/532546.png',
      bg: '#ffe0e5'
    },
    platform: 'algo',
  },
]

export const ALGORAND_NONFUNGIBLES = [
]

export const DEFINED_NONFUNGIBLES = [
  ...ETHEREUM_NONFUNGIBLES,
  ...ALGORAND_NONFUNGIBLES,
]
