import React from 'react'
import {
  Container,
  Flex,
  Spacer,
  Text,
  useDisclosure,
  Box,
} from '@djrhails/watcher-ui'

import { Link } from 'react-router-dom'
import { AccountDropdown } from '../AccountDropdown'
import { Network } from '../../constants'
import { SuperFormula } from '../SuperFormula'
import { AlgorandActivateModal, EthereumActivateModal } from '../ActivateModal'

export const Header: React.FC = () => {
  const { isOpen: isEthOpen, onOpen: onEthOpen, onClose: onEthClose } = useDisclosure()
  const { isOpen: isAlgoOpen, onOpen: onAlgoOpen, onClose: onAlgoClose } = useDisclosure()

  return (
    <Container as="header" py={3}>
      <Flex width="full" py={1}>
        <Link to="/">
          <Flex>
            <Box width={12}>
              <SuperFormula/>
            </Box>
            <Text fontSize={3} fontWeight="medium" p={3}>
          isomorph
            </Text>
          </Flex>
        </Link>
        <Spacer />

        <EthereumActivateModal
          isOpen={isEthOpen}
          onClose={onEthClose}
        />
        <AccountDropdown
          network={Network.Ethereum}
          onActivate={onEthOpen}
        />

        <AlgorandActivateModal
          isOpen={isAlgoOpen}
          onClose={onAlgoClose}
        />
        <AccountDropdown
          network={Network.Algorand}
          onActivate={onAlgoOpen}
        />
      </Flex>
      <Box width="full" height="0.2rem" bg="muted"/>
    </Container>
  )
}

export default Header