import React from 'react'
import {
  ModalBody,
  Flex,
  ModalFooter,
  Button,
  Text,
  Committee,
  CommitteeProps
} from '@djrhails/watcher-ui'
import { useDispatch } from 'react-redux'
import { nextStage } from '../../../state/transfer/action'
import { networkToVariantColor } from '../../../constants/Network'
import { ChainId, chainToNetwork } from '../../../constants/ChainId'
import { TransferProps } from '../TransferModal'
import { TransferStage } from '../../../state/transfer/state'

export function CommitteeStep({ info, libs }: TransferProps): React.ReactElement {
  const dispatch = useDispatch()
  const next = () => {
    dispatch(nextStage({
      originChainId: info.originChainId,
      assetKey: info.assetKey,
      stage: info.returning ? TransferStage.AWAITING_BURN : TransferStage.DEPLOY_PAIR_ASSET,
      libs,
    }))
  }

  const members: CommitteeProps['members'] = (info.signingCommittee ?? []).map((member) => {
    return {
      name: member.did,
      address: member.did,
      rating: member.approved ? 'approve' : 'pending', 
    }
  })

  return (
    <>
      <ModalBody>
        <Text fontWeight="semibold" pb={2}>SIGNING COMMITTEE</Text>
        <Flex width="full" height="20rem">
          <Committee
            members={members}
            committeeDiameter={200} // ~13rem
            padding={40} />
        </Flex>
            Your randomly selected committee to authenticate your transfer. They will only verify a {info.returning ? 'burn' : 'lock-up'}, and sign the corresponding asset, they never own your asset.
      </ModalBody>

      <ModalFooter justify="center">
        {!info.returning &&
        <Button
          buttonColor={networkToVariantColor(chainToNetwork(info.destinationChainId as ChainId))}
          onClick={next}
        >
          Create ~Asset
        </Button>}
        {info.returning && 
        <Button
          buttonColor={networkToVariantColor(chainToNetwork(info.originChainId as ChainId))}
          onClick={next}
        >
          Burn Asset
        </Button>}
      </ModalFooter>
    </>
  )
}
