import { randomEthereumAddress } from '@djrhails/watcher-ui'
import { createAction } from '@reduxjs/toolkit'
import { AppThunk } from '..'
import { CommitteeMember, TransferInfo, TransferStage } from './state'
import { isUndefined, random, range, times } from 'lodash'
import { AlgorandLibrary } from '../../providers/AlgorandWeb3Provider'
import { Web3Provider as EthereumLibrary } from '@ethersproject/providers'
import { fetchAlgorandAssets, parseAssetKey, updateAsset } from '../assets/action'
import { ChainId, chainToNetwork } from '../../constants/ChainId'
import { Network } from '../../constants'
import { BigNumber } from '@ethersproject/bignumber'

export interface TransferPayload {
  originChainId: number;
  assetKey: string;
}

export interface StartPayload extends TransferPayload {
  amount?: BigNumber;
  isopair?: string;
}

export interface NextPayload extends TransferPayload {
  stage: TransferStage;
  libs: {
    originLibrary: AlgorandLibrary | EthereumLibrary;
    destinationLibrary: AlgorandLibrary | EthereumLibrary;
  }
}

export interface UpdateTransferPayload extends TransferPayload {
  info: Partial<TransferInfo>;
}

export const abortTransfer = createAction('transfer/abortTransfer')
export const startTransfer = createAction<StartPayload>('transfer/startTransfer')
export const updateTransfer = createAction<UpdateTransferPayload>('transfer/updateTransfer')

export const nextStage = (payload: NextPayload): AppThunk => async (dispatch): Promise<void> => {

  dispatch(updateTransfer({
    originChainId: payload.originChainId,
    assetKey: payload.assetKey,
    info: {
      stage: payload.stage,
    }
  }))
  
  if (payload.stage == TransferStage.FINDING_COMMITTEE) {
    dispatch(selectCommittee({
      originChainId: payload.originChainId,
      assetKey: payload.assetKey,
    }))
  }

  if (payload.stage == TransferStage.DEPLOY_PAIR_ASSET) {
    dispatch(deployPaired(payload))
  }

  if (payload.stage == TransferStage.AWAITING_LOCKUP) {
    dispatch(lockAsset(payload))
  }

  if (payload.stage == TransferStage.VERIFY_LOCKUP) {
    dispatch(verifyLockup(payload))
  }

  if (payload.stage == TransferStage.PAIR_SIGNED) {
    dispatch(abortTransfer())
  }


  // ~Asset -> Asset
  if (payload.stage == TransferStage.AWAITING_BURN) {
    dispatch(burnAsset(payload))
  }

  if (payload.stage == TransferStage.VERIFY_BURN) {
    dispatch(verifyBurn(payload))
  }

  if (payload.stage == TransferStage.RELEASED) {
    dispatch(abortTransfer())
  }
}

export const selectCommittee = (payload: TransferPayload): AppThunk => async (dispatch): Promise<void> => {
  const memberList = (n: number): { did: string; approved: boolean; }[] => times(n, (_) => {
    const addr = randomEthereumAddress()
    return ({
      did: addr,
      approved: false,
    })
  })
    
  dispatch(updateTransfer({
    ...payload,
    info: {
      signingCommittee: [{
        did: '0x67C3fD74fd36456FC5b339Fd09Efa330ad3126eE',
        approved: false,
      }, ...memberList(5)]
    },
  }))
}

export const deployPaired = ({
  originChainId,
  assetKey,
  libs: {
    destinationLibrary
  }
}: NextPayload): AppThunk => async (dispatch, getState): Promise<void> => {

  try {
    console.log(`Deploying paired asset for ${assetKey} to Algorand`)

    const assetId = parseAssetKey(assetKey)

    const { assets } = getState()
    const originTracker = assets.trackers[originChainId][assetId.trackerId]
    const originAsset = assets.assets[originChainId][assetKey]
  
    console.log(originAsset)

    if (chainToNetwork(originChainId) === Network.Algorand) {
      Promise.reject('Cloning not supported on Ethereum yet')
    }

    const algoLib = destinationLibrary as AlgorandLibrary

    const txn = await algoLib.createAsset({
      decimals: originTracker.decimals,
      supply: originTracker.total,
      unitName: '~' + (originTracker.symbol ?? originTracker.name.toUpperCase()),
      assetName: originAsset.data?.name ? `${originAsset.data.name} (${originTracker.name})` : originTracker.name,
      assetURL: 'https://isomorph.network',
      metadata: {
        isopair: assetKey,
        originChainId: originChainId.toString(16),
        tokenUri: originAsset.tokenUri ?? `https://api.isomorph.network/v1/asset/${assetKey}/pair`,
      },
    })

    console.log('Transaction: ', txn)

    const ptx = await algoLib.waitForConfirmation(txn.txId)

    console.log('Pending Transaction: ', ptx)

    const pairedAssetKey = ptx['asset-index']?.toString()

    console.log('Paired Asset Key: ', pairedAssetKey)

    const connectedAccount = await algoLib.getActiveAccount() as string
    dispatch(fetchAlgorandAssets(algoLib, connectedAccount))
    dispatch(updateTransfer({
      originChainId,
      assetKey,
      info: {
        pairedAssetKey: pairedAssetKey,
      }
    }))
    dispatch(updateAsset({
      chainId: originChainId,
      assetKey: assetKey,
      asset: {
        isopair: pairedAssetKey,
      }
    }))
  } catch (err) {
    dispatch(updateTransfer({
      originChainId,
      assetKey,
      info: {
        stage: TransferStage.ERROR,
        error: err,
      }
    }))
  }
} 

export const lockAsset = ({ originChainId, assetKey }: NextPayload): AppThunk => async (dispatch): Promise<void> => {

  await new Promise((resolve) => { setTimeout(resolve, 1000) })

  dispatch(updateAsset({
    chainId: originChainId,
    assetKey: assetKey,
    asset: {
      locked: true,
    }
  }))

}

const verifyBurn = (payload: NextPayload): AppThunk => async (dispatch, getState): Promise<void> => {
  const { transfer } = getState()
  const activeTransfer = transfer.transfers[payload.originChainId][payload.assetKey]

  dispatch(fakeVerify(payload))
  
  if (activeTransfer.destinationChainId && activeTransfer?.pairedAssetKey) {
    dispatch(updateAsset({
      chainId: activeTransfer.destinationChainId,
      assetKey: activeTransfer.pairedAssetKey,
      asset: {
        locked: false,
        isopair: undefined,
      }
    }))
  } else {
    console.warn('Can\'t release in UI:', activeTransfer)
  }

}

const verifyLockup = (payload: NextPayload): AppThunk => async (dispatch): Promise<void> => {
  dispatch(fakeVerify(payload))
}

export const fakeVerify = ({ originChainId, assetKey }: NextPayload): AppThunk => async (dispatch, getState): Promise<void> => {
  const { transfer } = getState()

  const originalCommittee = transfer.transfers[originChainId][assetKey].signingCommittee

  const committee = [...originalCommittee as CommitteeMember[]]
  
  for (const i of range(0, committee?.length)) {
    await new Promise((resolve) => { setTimeout(resolve, random(50, 2000)) })

    dispatch(updateTransfer({
      originChainId: originChainId,
      assetKey: assetKey,
      info: {
        signingCommittee: committee.map((_, idx) => {
          return {
            did: committee[idx].did,
            approved: committee[i].approved || idx <= i,
          }
        }),
      },
    }))

  }
}

export const burnAsset = ({
  originChainId,
  assetKey,
  libs: {
    originLibrary
  }
}: NextPayload): AppThunk => async (dispatch, getState): Promise<void> => {

  try {
    console.log(`Burning paired asset ${assetKey} on Algorand`)

    const assetId = parseAssetKey(assetKey)

    const { assets, transfer } = getState()
    const originTracker = assets.trackers[originChainId][assetId.trackerId]
    const originAsset = assets.assets[originChainId][assetKey]
    const currentTransfer = transfer.transfers[originChainId][assetKey]

    console.log(originAsset, currentTransfer, originTracker)

    if (chainToNetwork(originChainId) === Network.Ethereum) {
      throw new Error('Burning not supported on Ethereum yet')
    }

    const algoLib = originLibrary as AlgorandLibrary

    const txn = await algoLib.destroyAsset(Number(assetId.trackerId))

    console.log('Transaction: ', txn)

    const ptx = await algoLib.waitForConfirmation(txn.txId)

    console.log('Pending Transaction: ', ptx)

    const connectedAccount = await algoLib.getActiveAccount() as string
    dispatch(fetchAlgorandAssets(algoLib, connectedAccount))
    dispatch(updateAsset({
      chainId: originChainId,
      assetKey: assetKey,
      asset: {
        burned: true,
      }
    }))
    if (!isUndefined(currentTransfer)) {
      dispatch(updateAsset({
        chainId: currentTransfer.destinationChainId as ChainId,
        assetKey: assetKey,
        asset: {
          locked: false,
          isopair: undefined,
        }
      }))
    }

  } catch (err) {
    dispatch(updateTransfer({
      originChainId,
      assetKey,
      info: {
        stage: TransferStage.ERROR,
        error: err.toString(),
      }
    }))
  }
} 