import { useActiveEthereum } from '../useActiveEthereum'
import { Contract } from '@ethersproject/contracts'
import { useContract } from '.'
import { MORPH_ADDRESSES, MORPH_ABI } from '../../constants/abis/morph'

export function useMorphContract(): Contract | null {
  const { chainId } = useActiveEthereum()
  const morphAddress = chainId 
    ? MORPH_ADDRESSES[chainId] ?? undefined
    : undefined
  return useContract(morphAddress, MORPH_ABI, true)
}
