import React from 'react'
import {
  Box,
} from '@djrhails/watcher-ui'
import { BlocksSection } from './BlocksSection'
import { TokenSection } from './TokenSection'
import { NiftySection } from './NFTSection'
import { DEFINED_NONFUNGIBLES } from '../../constants/tokens/TokenSets'
export const Dashboard: React.FC = () => {
  return (
    <>
      <BlocksSection/>
      <Box py={3}/>
      <TokenSection/>
      <Box py={3}/>
      <NiftySection nifties={DEFINED_NONFUNGIBLES as any}/>
    </>
  )
}

export default Dashboard