import React, { PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'
import { Web3Provider as EthereumWeb3Provider } from '@ethersproject/providers'
import { createWeb3ReactRoot } from '@web3-react/core'
import { Provider } from 'react-redux'

import { Network } from './constants'
import App from './pages/App'
import { store } from './state'
import NetworkUpdater from './state/network/updater'
import MulticallUpdater from './state/multicall/updater'
import AssetUpdater from './state/assets/updater'
import { AlgorandLibrary } from './providers/AlgorandWeb3Provider'

const EthereumWeb3NetworkProvider = createWeb3ReactRoot(Network.Ethereum)
const EthereumWeb3FallbackProvider = createWeb3ReactRoot(Network.EthereumFallback)
const AlgorandWeb3NetworkProvider = createWeb3ReactRoot(Network.Algorand)

function getEthereumWeb3Provider(provider: any): EthereumWeb3Provider {
  const library = new EthereumWeb3Provider(provider)
  library.pollingInterval = 15000
  return library
}

function getAlgorandWeb3Provider(provider: any, connector: any): AlgorandLibrary {
  const library = new AlgorandLibrary(provider, connector)
  return library
}

// We have to Web3ReactProviders here, one is always on, and the second is for user control. Means there is always a read only connection.
// TODO(DJRHails): Expand Web3ReactProvider with an AlgorandProvider as well.

const Updaters: React.FC = () => {
  return (
    <>
      <AssetUpdater />
      <NetworkUpdater />
      <MulticallUpdater />
    </>
  )
}

const NetworkProviders: React.FC = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <EthereumWeb3NetworkProvider
      getLibrary={getEthereumWeb3Provider}
    >
      <EthereumWeb3FallbackProvider
        getLibrary={getEthereumWeb3Provider}
      >
        <AlgorandWeb3NetworkProvider
          getLibrary={getAlgorandWeb3Provider}
        >
          {children}
        </AlgorandWeb3NetworkProvider>
      </EthereumWeb3FallbackProvider>
    </EthereumWeb3NetworkProvider>
  )
}

const Root: React.FC = () => (
  <React.StrictMode>
    <NetworkProviders>
      <Provider store={store}>
        <Updaters />
        <App />
      </Provider>
    </NetworkProviders>
  </React.StrictMode>
)

ReactDOM.render(
  <Root />,
  document.getElementById('root')
)
