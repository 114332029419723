import invariant from 'tiny-invariant'
import { ChainId } from '../index'
import { EIP20 } from './EIP20'

export abstract class Tracker {
  public readonly chainId: ChainId;
  public readonly trackerId: string;
  public readonly decimals: number;
  public readonly symbol?: string;
  public readonly name?: string;
  public readonly total?: number;

  constructor(
    chainId: ChainId,
    trackerId: string,
    decimals: number,
    symbol?: string,
    name?: string,
    total?: number,
  ) {
    this.chainId = chainId
    this.trackerId = trackerId
    this.decimals = decimals
    this.symbol = typeof symbol === 'string' ? symbol : undefined
    this.name = typeof name === 'string' ? name : undefined
    this.total = typeof total === 'number' ? total : undefined
  }

  equals(other: EIP20): boolean {
    const equal = this.chainId === other.chainId && this.trackerId === other.trackerId
    if (equal) {
      invariant(this.decimals === other.decimals, 'DECIMALS')
      if (this.symbol && other.symbol)
        invariant(this.symbol === other.symbol, 'SYMBOL')
      if (this.name && other.name)
        invariant(this.name === other.name, 'NAME')
    }
    return equal
  }

  sortsBefore(other: EIP20): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.trackerId !== other.trackerId, 'ADDRESSES')
    return this.trackerId.toLowerCase() < other.trackerId.toLowerCase()
  }
}
