import { SuperFormulaParameters, Point, Path } from './index'

export const MIN_PARAMS: Required<SuperFormulaParameters> = {
  a: 1.0,
  b: 1.0,
  m: 1.0,
  n1: 2.0,
  n2: 4.0,
  n3: 4.0,
  iterations: 1.0,
  decay: .05,
}

export const MAX_PARAMS: Required<SuperFormulaParameters> = {
  a: 5, //20.0,
  b: 20.0,
  m: 10.0,
  n1: 20.0, // 80.0,
  n2: 20.0, // 80.0,
  n3: 20.0, // 80.0,
  iterations: 20,
  decay: .2,
}

const random_bm = (): number => {
  let u = 0, v = 0
  while (u === 0)
    u = Math.random()
  while (v === 0)
    v = Math.random()
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v)
  num = num / 10.0 + 0.5
  if (num > 1 || num < 0)
    return random_bm()
  return num
}

export const random = (lower: number, upper: number, gauss: boolean): number => {
  const range = gauss ? random_bm() : Math.random()
  return range * (upper - lower) + lower
}

export const randomParams = (): SuperFormulaParameters => (
  {
    a: random(MIN_PARAMS.a, MAX_PARAMS.a, true),
    b: random(MIN_PARAMS.b, MAX_PARAMS.b, false),
    m: 2 * Math.round(random(MIN_PARAMS.m, MAX_PARAMS.m, false)),
    n1: random(MIN_PARAMS.n1, MAX_PARAMS.n1, false),
    n2: random(MIN_PARAMS.n2, MAX_PARAMS.n2, false),
    n3: random(MIN_PARAMS.n3, MAX_PARAMS.n3, false),
    iterations: random(MIN_PARAMS.iterations ?? 1, MAX_PARAMS.iterations ?? 1, false),
    decay: random(MIN_PARAMS.decay ?? 0, MAX_PARAMS.decay ?? 0, false),
  }
)

const scaleSlice = (hex: string, param: keyof SuperFormulaParameters) => {
  const value = parseInt(hex, 16) || 0
  const min = MIN_PARAMS[param] || 0
  const max = MAX_PARAMS[param] || 1
  const range = max - min
  return value * range / 255  + min
}

// Takes a hexidecimal string of 16 characters (e.g. 00FF00FF00FF00FF) and rescales each byte to form the SuperFormulaParameters
export const parseParams = (hash: string): SuperFormulaParameters => (
  {
    a: scaleSlice(hash.slice(0, 2), 'a'),
    b: scaleSlice(hash.slice(2, 4), 'b'),
    m: Math.round(scaleSlice(hash.slice(4, 6), 'm')) * 2,
    n1: scaleSlice(hash.slice(6, 8), 'n1'),
    n2: scaleSlice(hash.slice(8, 10), 'n2'),
    n3: scaleSlice(hash.slice(10, 12), 'n3'),
    iterations: scaleSlice(hash.slice(12, 14), 'iterations'),
    decay: scaleSlice(hash.slice(14, 16), 'decay'),
  }
)

const superFormulaPoint = (phi: number, p: SuperFormulaParameters): Point => {
  const point: Point = { x: 0, y: 0 }

  let r
  let t1, t2

  t1 = Math.cos(p.m * phi / 4) / p.a
  t1 = Math.abs(t1)
  t1 = Math.pow(t1, p.n2)

  t2 = Math.sin(p.m * phi / 4) / p.b
  t2 = Math.abs(t2)
  t2 = Math.pow(t2, p.n3)

  r = Math.pow(t1 + t2, 1 / p.n1)

  if (Math.abs(r) === 0) {
    return point
  }

  r = 1 / r
  point.x = r * Math.cos(phi)
  point.y = r * Math.sin(phi)

  return point
}
const superFormulaPath = (resolution: number, p: SuperFormulaParameters): Path => {
  const phi = (Math.PI * 2) / resolution

  const path: Path = []

  for (let i = 0; i <= resolution; i++) {
    path.push(superFormulaPoint(phi * i, p))
  }

  return path
}

export const decayParams = (k: number, params: SuperFormulaParameters, manualDecay?: number): SuperFormulaParameters => {
  const decay = manualDecay || params.decay || 0
  return {
    a: params.a - k * decay,
    b: params.b - k * decay,
    m: params.m,
    n1: params.n1 - k * decay,
    n2: params.n2 - k * decay,
    n3: params.n3 - k * decay,
    iterations: params.iterations,
    decay: params.decay,
  }
}

export const superFormulaPaths = (resolution: number, p: SuperFormulaParameters): Path[] => {
  const paths: Path[] = []
  for (let k = 0; k < (p.iterations || 1); k++) {
    paths.push(superFormulaPath(resolution, decayParams(k, p)))
  }

  return paths
}

export const scalePaths = (targetRadius: number, paths: Path[]): Path[] => {
  const largestRadius = paths.flatMap((p) => p).reduce((largest, p) => {
    const radius = Math.sqrt(p.x * p.x + p.y * p.y)
    return radius > largest ? radius : largest
  }, 0)
  const scaleFactor = targetRadius / largestRadius

  const scaledPaths: Path[] = paths.map((path) => path.map((p) => {
    return { x: p.x * scaleFactor, y: p.y * scaleFactor }
  }))

  return scaledPaths
}

export const path2SVGInstruction = (path: Path): string => {
  const pointSpread = path.slice(1).map(point => {
    return `${(point.x).toFixed(2)} ${(point.y).toFixed(2)}`
  })

  return `M ${path[0].x} ${path[0].y} ` + pointSpread.join(' ') + ' Z'
}
