import React from 'react'
import {
  Button,
  Dropdown,
  DropdownButton,
  DropdownList,
  DropdownGroup,
  Badge,
  Flex,
  Icon,
  DropdownItem
} from '@djrhails/watcher-ui'
import { Network, CHAIN_LABELS, ChainId } from '../../constants'
import { AccountBlock } from '../AccountBlock'
import { isMobile } from 'react-device-detect'
import { networkToVariantColor } from '../../constants/Network'
import { useNetwork } from '../../hooks/useNetwork'

export interface AccountDropdownProps {
  network: Network;
  onActivate(): void;
}

export const AccountDropdown: React.FC<AccountDropdownProps> = ({ network, onActivate }: AccountDropdownProps) => {
  const { account, chainId: web3ChainId, connector, error } = useNetwork(network)
  
  const chainId = web3ChainId as ChainId
  const hasChainLabel = !isMobile && chainId && CHAIN_LABELS[chainId as ChainId]
  const hasDropdown = account && !error
  const color = networkToVariantColor(network)
  const tryChangeChain = async () => {
    try {
      const cc = connector as any
      if (connector && cc.changeChainId && connector.supportedChainIds) {
        const chainId = await connector.getChainId() as number
        const supported = connector.supportedChainIds
        const indexInSupportedChains = (supported.indexOf(chainId) + 1) % supported.length
        cc.changeChainId(supported[indexInSupportedChains])
      }
    } catch (err) {
      console.warn(err)
    }
  }

  return (
    <>
      { hasChainLabel && 
        <Badge
          variantColor={color}
          onClick={tryChangeChain}
        >
          {CHAIN_LABELS[chainId]}
        </Badge>
      }
      <Dropdown>
        <DropdownButton
          as={Button}
          onClick={!hasDropdown ? onActivate : undefined}
          variant="outline"
          buttonColor={color} // TODO(DJRHails): Platform color refactor
          textColor="black"
          buttonSize="sm"
          mr={2}
        >
          <AccountBlock
            network={network}
            account={account ?? undefined}
            error={error}
          />
        </DropdownButton>
        <DropdownList>
          { hasDropdown && <DropdownGroup title="Connected">
            <AccountBlock
              network={network}
              account={account ?? undefined}
              px={2}
              py={3}
              copyable
            />
            <DropdownItem onClick={onActivate}>
              <Flex
                width={1}
                px={2}
                justify="space-between"
              >
                Add another address
                <Icon name="Add"/>
              </Flex>
            </DropdownItem>
          </DropdownGroup> }
        </DropdownList>

      </Dropdown>
    </>
  )
}