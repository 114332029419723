import { configureStore, getDefaultMiddleware, ThunkAction, Action } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import multicall from './multicall/reducer'
import network from './network/reducer'
import assets from './assets/reducer'
import transfer from './transfer/reducer'

const PERSISTED_KEYS: string[] = ['']

export const store = configureStore({
  reducer: {
    multicall,
    network,
    assets,
    transfer,
  },
  middleware: [...getDefaultMiddleware(), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS })
})

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, AppState, unknown, Action<string>>