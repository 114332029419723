import { useEffect, useMemo } from 'react'
import { useActiveEthereum } from './useActiveEthereum'
import { EIP20, ALL_FUNGIBLE_TOKENS, EIP721, ALL_NONFUNGIBLE_TOKEN } from '../constants/tokens'
import { useEIP20BalancesWithLoading, useEIP721BalancesWithLoading } from './useTokenBalances'
import { BigNumber } from '@ethersproject/bignumber'
import { useTokenEnumerationWithLoading, useTokenUrisWithLoading as useTokenIdsAndUrisWithLoading } from './useTokenEnumeration'
import { addEthToken, addPrices, loadEthereumNifty } from '../state/assets/action'
import { useDispatch } from 'react-redux'
import useDebounce from './useDebounce'

export function useAllEIP20(): { [address: string]: EIP20 } {
  const { chainId } = useActiveEthereum() // TODO(DJRHails): Update for Algorand
  
  // TODO(DJRHails): Support custom tokens

  return useMemo(() => {
    if (!chainId) return {}
    const tokens = { ...ALL_FUNGIBLE_TOKENS[chainId] }
    return tokens
  }, [chainId])
}

export function useAllEIP721(): { [address: string]: EIP721 } {
  const { chainId } = useActiveEthereum()

  return useMemo(() => {
    if (!chainId) return {}
    const tokens = { ...ALL_NONFUNGIBLE_TOKEN[chainId] }
    return tokens
  }, [chainId])
}

export function useAllEthereumTokensWithLoading(): void  {
  const { account, chainId } = useActiveEthereum()
  const dispatch = useDispatch()

  const chainTokens = useAllEIP20()
  const chainTokenList = useMemo(() => Object.values(chainTokens), [chainTokens])
  
  const [tokenValueMap, ] = useEIP20BalancesWithLoading(account ?? undefined, chainTokenList)

  const debouncedState = useDebounce(tokenValueMap, 100)

  useEffect(() => {
    Object.entries(debouncedState)
      .forEach((entry) => {
        const tracker = chainTokens[entry[0]]
        dispatch(addEthToken({
          chainId: tracker.chainId,
          trackerId: tracker.trackerId,
          asset: {
            owner: account as string,
            balance: entry[1].toHexString(),
          }
        }))
      })
  }, [dispatch, account, chainId, debouncedState])

  useEffect(() => {
    const keys = Object.keys(debouncedState)
    if (keys.length > 0) {
      dispatch(addPrices({
        identifiers: keys.map((key) => {
          return {
            chainId: chainTokens[key].chainId,
            trackerId: chainTokens[key].trackerId,
          }
        })
      }))
    }
  }, [debouncedState])
}

export function useAllEthereumNonfungiblesWithLoading(): { loading: boolean } {
  const { account, chainId } = useActiveEthereum()

  // Example Account with many Known Origin, SuperRare 
  // const account = '0x54d7F921785eBE46010d83c73712e80dfaff1e81'
  const dispatch = useDispatch()
  const chainTokens = useAllEIP721()
  // Memo is necessary else spins
  const chainTokenList = useMemo(() => Object.values(chainTokens), [chainTokens])

  const [tokenSet, balanceLoading] = useEIP721BalancesWithLoading(account ?? undefined, chainTokenList)

  const [owned, ownedLoading] = useTokenEnumerationWithLoading(tokenSet, account ?? undefined)

  const { tokenUris, loading: tokenUriLoading } = useTokenIdsAndUrisWithLoading(owned)

  const loading = balanceLoading || ownedLoading || tokenUriLoading

  useEffect(() => {
    Object.keys(tokenUris).forEach((addr) => {
      const eip721 = chainTokens[addr]
      tokenUris[addr].forEach((entry: {id: BigNumber; uri: string}) => {
        dispatch(loadEthereumNifty({
          chainId: eip721.chainId,
          trackerId: eip721.trackerId,
          asset: {
            innerId: entry.id.toHexString(),
            owner: account as string,
            balance: 1,
            tokenUri: entry.uri,
          }
        }))
      })
    })
  }, [dispatch, account, chainId, chainTokens, tokenUris])
  

  return {
    loading
  }
}