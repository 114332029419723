import { useWeb3React } from '@web3-react/core'
import { Network, ChainId } from '../constants'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { AlgorandLibrary } from '../providers/AlgorandWeb3Provider'
import { useEffect, useState } from 'react'

export function useActiveAlgorand(): Web3ReactContextInterface<AlgorandLibrary> & { chainId?: ChainId } {
  const [account, setAccount] = useState<string>()
  
  const algoContext = useWeb3React<AlgorandLibrary>(Network.Algorand)
  const connector = algoContext.connector

  useEffect(() => {
    const callback = (): void => {
      if (!account) {
        connector?.getAccount()
          .then((newAccount) => {
            setAccount(newAccount ?? undefined)
          })
      }
    }

    connector?.addListener('Web3ReactUpdate', callback)
    return (): void => {
      connector?.removeListener('Web3ReactUpdate', callback)
    }
  }, [connector, account])

  return {...algoContext, chainId: algoContext.library?.getCurrentChain(), account: account}
}
