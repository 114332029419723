import React from 'react'
import {
  Text,
  Heading,
  Container,
  Table,
  IconProps,
  PlatformDefiniton} from '@djrhails/watcher-ui'
import { useTokens, Token } from '../../state/assets/hooks'
import { Network } from '../../constants'
import { chainToPlatform } from '../../constants/ChainId'
import { SkeletonAssetRow } from '../../components/AssetRow/Skeleton'
import { AssetRow } from '../../components/AssetRow/AssetRow'
export interface TokenDisplay {
  token: Token;
  icon?: IconProps;
  platform?: PlatformDefiniton;
}

export const TokenSection: React.FC = () => {
  const algoTokens = useTokens(Network.Algorand)
  const ethTokens = useTokens(Network.Ethereum)
  const tokens = [...ethTokens, ...algoTokens]

  const displayTokens: TokenDisplay[] = tokens
    .filter((token) => token.tracker?.decimals != 0)
    .map((token) => {
      return {
        token: token,
        platform: chainToPlatform(token.chainId),
      }
    })

  // TODO(djrhails): Add loading back in.
  const loading = false
  if (displayTokens.length === 0) {
    return null
  }

  return (
    <Container as="section">
      <Heading.h4 my={3}>
        Tokens
      </Heading.h4>
      <Table scrollX sx={{
        'thead tr th:last-child': {
          textAlign: 'right',
        },
        'tbody tr td:last-child': {
          textAlign: 'right',
        }
      }}>
        <thead>
          <tr>
            <th>Asset</th>
            <th>Balance</th>
            <th><Text mr={2}>Actions</Text></th>
          </tr>
        </thead>
        <tbody>
          {loading 
            ? <>
              <SkeletonAssetRow/>
              <SkeletonAssetRow/>
            </>
            : null}
          {displayTokens?.map((token, idx) => (
            <AssetRow key={idx} {...token}/>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

