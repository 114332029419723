import invariant from 'tiny-invariant'
import warning from 'tiny-warning'
import { getAddress } from '@ethersproject/address'

import { ChainId } from '../index'
import { shortenAddress } from '@djrhails/watcher-ui'
import { Tracker } from './Tracker'

export interface EIP721Metadata {
  name?: string;
  description?: string;
  image?: string;
  
  // Extra
  timestamp?: number;
  language?: string;

  // OpenSea
  attributes?: OpenSeaAttribute[]; // EIPs#1071
  externalUrl?: string;
  backgroundColor?: string;
  animationUrl?: string;
  youtubeUrl?: string;

  // Rare Bits
  imageUrl?: string;
  homeUrl?: string;
  tags?: string[];
  properties?: RareBitProperty[];

  // MCH
  extraData?: Record<string, string>;
}

interface OpenSeaAttribute {
  traitType: string;
  value: Record<string, string>;
  displayType?: string;
  maxValue?: number;
}

interface RareBitProperty {
  key: string;
  value: Record<string, string>;
  type: string;
}

export class EIP721 extends Tracker {

  constructor(chainId: ChainId, address: string, symbol?: string, name?: string) {
    const checksummedAddress = address
    try {
      const checksummedAddress = getAddress(address)
      warning(address === checksummedAddress, `${address} is not checksummed.`)
    } catch (error) {
      invariant(false, `${address} is not a valid address.`)
    }
    const definedName = typeof name === 'string' ? name : shortenAddress(checksummedAddress)

    super(chainId, address, 0, symbol, definedName, 1)
  }
}