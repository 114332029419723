import { SummariseStep } from './SummariseStep'
import { CommitteeStep } from './CommitteeStep'
import { PairStep } from './PairStep'
import { VerifyStep } from './VerifyStep'
import { LockStep } from './LockStep'
import { BurnStep } from './BurnStep'
import { ErrorStep } from './ErrorStep'

export default {
  Summarise: SummariseStep,
  Committee: CommitteeStep,
  Pair: PairStep,
  Verify: VerifyStep,
  Lock: LockStep,
  Burn: BurnStep,
  Error: ErrorStep,
}